@use "sass:math";
$z-index-levels: ("100" 100, "200" 300, "300" 450, "400" 600, "500" 750, "600" 900, "700" 1050, "800" 1200, "900" 1350, "1000" 10000);
$position-values: ("1" 1%, "2" 2%, "3" 3%, "4" 4%);
$positions: ("top" "top", "bottom" "bottom", "start" "left", "end" "right");

@each $name, $value in $z-index-levels {
	.z-index-#{$name} {
		z-index: #{$value} !important;
	}
}
// example start-0 top-10
// example start-50 top-50 translate-middle
// example start-50 translate-middle

@for $i from 0 through 20 {
	$vname: ($i * 5);
	$vvalue: percentage(math.div(($i * 5), 100));

	@each $pname, $pvalue in $positions {
		.#{$pname}-#{$vname} {
			#{$pvalue}: #{$vvalue} !important;
		}
	}
}

@for $i from 0 through 4 {
	$vname: $i;
	$vvalue: percentage(math.div($i, 100));

	@each $pname, $pvalue in $positions {
		.#{$pname}-#{$vname} {
			#{$pvalue}: #{$vvalue} !important;
		}
	}
}

.translate-middle-x {
	transform: translateX(-50%);
}

.translate-middle-y {
	transform: translateY(-50%);
}

.translate-middle {
	transform: translate(-50%, -50%);
}

.sticky-top {
	position: sticky !important;
}

.float-top-right-fixed {
	position: fixed;
	right: 10px;
	top: $topbar-height + 10px;
}

.float-bottom-right-fixed {
	position: fixed;
	right: 10px;
	bottom: 1.125rem;
}

.float-top-left-fixed {
	position: fixed;
	left: 60px;
	top: $topbar-height + 10px;
}

.float-bottom-left-fixed {
	position: fixed;
	left: 10px;
	bottom: 1.125rem;
}

.float-top-right {
	position: absolute;
	right: 10px;
	top: 1.125rem;
}

.float-bottom-right {
	position: absolute;
	right: 10px;
	bottom: 1.125rem;
}

.float-top-left {
	position: absolute;
	left: 10px;
	top: 1.125rem;
}

.float-bottom-left {
	position: absolute;
	left: 10px;
	bottom: 1.125rem;
}

.float-bottom {
	position: absolute;
	bottom: 1.125rem;
}

// @each $position-name,
// $prop-values in $positions {

//     @each $name,
//     $value in $position-values {
//         .#{$position-name}-#{$name} {
//             #{$prop-values}: #{$value} !important;
//         }

//     }
// }
