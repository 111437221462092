@use "sass:math";

//
// buttons.scss
//

.btn {
  .mdi {
    &:before {
      line-height: initial;
    }
  }
}

.btn-rounded {
  border-radius: 2em;
}

.btn-circle {
  width: 2em;
  height: 2em;
  padding: 0.2em 0em;
  border-radius: 1em;
  text-align: center;
  font-size: inherit;
  line-height: inherit;
}

// Button Shadows
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-shadow($value);
  }
}

//
// Link buttons
//

// Make a button look and behave like a link

@each $color, $value in $theme-colors {
  .btn-link-#{$color} {
    font-weight: $font-weight-normal;
    color: lighten($value, 10%);
    text-decoration: $link-decoration;

    @include hover() {
      color: darken($value, 15%);
      text-decoration: none;
    }

    &:focus,
    &.focus {
      text-decoration: $link-hover-decoration;
    }

    &:disabled,
    &.disabled {
      color: $btn-link-disabled-color;
      pointer-events: none;
    }
    // No need for an active state here
  }
}

$main-color-snazzy: rgb(144, 202, 249);
$main-shadow-snazzy: rgba(0, 0, 0, 0.9);
$menu-btn-snazzy-size: 3rem;
$menu-btn-burger-snazzy-size: (math.div(50px, 80px) * $menu-btn-snazzy-size);
$menu-btn-burger-snazzy-bar-height: (math.div(3px, 80px) * $menu-btn-snazzy-size);
$menu-btn-burger-snazzy-translate: (math.div(35px, 80px) * $menu-btn-snazzy-size);
$menu-btn-burger-snazzy-transformY: (math.div(16px, 80px) * $menu-btn-snazzy-size);

.menu-btn {
  position: relative;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  width: $menu-btn-snazzy-size;
  height: $menu-btn-snazzy-size;
  cursor: pointer;
  transition: all 0.5s ease-in-out;

  .menu-btn-burger {
    width: $menu-btn-burger-snazzy-size;
    height: $menu-btn-burger-snazzy-bar-height;
    background: $main-color-snazzy;
    border-radius: 5px;
    box-shadow: 0 2px 5px $main-shadow-snazzy;
    transition: all 0.5s ease-in-out;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: $menu-btn-burger-snazzy-size;
      height: $menu-btn-burger-snazzy-bar-height;
      background: $main-color-snazzy;
      border-radius: 5px;
      box-shadow: 0 2px 5px $main-shadow-snazzy;
      transition: all 0.5s ease-in-out;
    }

    &::before {
      transform: translateY($menu-btn-burger-snazzy-transformY * -1);
    }

    &::after {
      transform: translateY($menu-btn-burger-snazzy-transformY);
    }
  }

  &.open {
    .menu-btn-burger {
      transform: translateX($menu-btn-burger-snazzy-size * -1);
      background: transparent;
      box-shadow: none;
      &::before {
        transform: rotate(45deg)
          translate($menu-btn-burger-snazzy-translate, $menu-btn-burger-snazzy-translate * -1);
      }
      &::after {
        transform: rotate(-45deg)
          translate($menu-btn-burger-snazzy-translate, $menu-btn-burger-snazzy-translate);
      }
    }
  }
  /* border: 3px solid #fff; */
}
