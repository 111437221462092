// 
// custom-forms.scss
//


@each $color,
$value in $theme-colors {
    .custom-checkbox-#{$color},
    .custom-radio-#{$color} {
        .custom-control-input {
            &:checked~.custom-control-label:before {
                background-color: $value;
                border-color: $value;
            }
        }
    }
}



input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.rbt-input-hint{
    color:$sg-500 !important
}
.rbt.coingecko{
    //     background: url(https://saxoncircle.blob.core.windows.net/crypto-images/CoinGecko%20Logo.svg) no-repeat scroll 7px 7px;
    // }
    .coingecko-input-icon{
        position: absolute;
        right: 0.3rem;         
        top: 15%;
        height: 70%;
        // width: 30px;
        z-index: 1000;
        text-align: right;
    }
    
}

.rbt-input:disabled{
    color:$sg-400 !important
}


