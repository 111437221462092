.toast-container {
        position: fixed;
        top: $topbar-height + 10px;        
        width: max-content;
        max-width: 100%;
        pointer-events: none;

        > :not(:last-child) {
                margin-bottom: $toast-spacing;
        }
        &.toast-right{
                right: 10px;
        }        
}

.toast {
        width: $toast-max-width;
        max-width: 100%;
        @include font-size($toast-font-size);
        color: $toast-color;
        pointer-events: auto;
        background-color: $toast-background-color;
        background-clip: padding-box;
        border: $toast-border-width solid $toast-border-color;
        box-shadow: $toast-box-shadow;
        @include border-radius($toast-border-radius);

        &:not(.showing):not(.show) {
                opacity: 0;
        }

        &.hide {
                display: none;
        }

        &.shadow {
                box-shadow: 0 10px 20px rgb(0, 0, 0, 19%), 0 6px 6px rgb(0, 0, 0, 23%) !important;
        }

        &.animation-top-right {
                position: relative;
                min-width: 300px;
                animation-name: rightToLeft;
                animation-duration: .4s;
                animation-iteration-count: 1;
                animation-direction: normal;
        }
        &.animation-top-left {
                position: relative;
                min-width: 300px;
                animation-name: leftToRight;
                animation-duration: .4s;
                animation-iteration-count: 1;
                animation-direction: normal;
        }
}

@keyframes rightToLeft {
        0% {
                right: -20%;
        }

        100% {
                right: 10px;
        }
}
@keyframes leftToRight {
        0% {
                left: -20%;
        }

        100% {
                right: 10px;
        }
}



#toast,
#toast[hidden] {
        display: block;
        position: fixed;
        left: 50%;
        top: 0;
        background: $light;
        padding: 6px 20px;
        min-width: 30%;
        max-width: 80%;
        transform: translateX(-50%);
        font-weight: bolder;
        color: var(--dark);
        text-align: center;
        transition: all 500ms ease;
        visibility: visible;
        white-space: pre-wrap;
        line-height: 2;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        will-change: transform, opacity;
        overflow: hidden;
        contain: content;
        z-index: 10000;
}

#toast[hidden] {
        transform: translateX(-50%) translateY(120%);
        opacity: 0;
}

#toast.error {
        color: $danger;
        background: lighten($danger, 80%);
}