.ql-mention-list-container {
    width: auto;
    border: 1px solid $secondary;
    border-radius: 4px;
    background-color: $dark-500;
    box-shadow: 0 2px 12px 0 rgba(30,30,30,0);
    z-index: 9001
}

.ql-mention-list {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden
}

.ql-mention-list-item {
    cursor: pointer;
    height: 1.7rem;
    line-height: 1.7rem;    
    padding: 0 20px;
    vertical-align: middle;
    color: white
}

.ql-mention-list-item.selected {
    background-color: $primary;
    text-decoration: none
}

.mention>a {            
    color: $primary

}
#editor > div.ql-editor > p > span > span > a

.mention>span {
    margin: 0 3px;
    height: 24px;
    width: 65px;
    border-radius: 0px;    
    padding: 3px 0;
    margin-right: 2px;
    background-color: transparent;    
    border-bottom: 0px solid #007bff;
    color: white
}

.ql-mention-denotation-char{
    color: $primary
}

.ql-snow a{
    color: $primary
}


