.grow-sm {
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.025);
  }
}
.grow {
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
}

.grow-lg {
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
}
