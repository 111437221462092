.sdi::before {
	margin: 0;
	box-sizing: border-box;
}

.sdi-40px {
	&::before {
		font-size: 40px !important;
		background-size: 40px 40px !important;
		background-position: center center;
		background-repeat: no-repeat;
		width: 40px !important;
		height: 40px !important;
		line-height: 40px;
	}
}
.sdi:before,
.sdi-set {
	display: inline-block;
	font-size: inherit;
	text-rendering: auto;
	line-height: inherit;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	// background-size: 18px 18px;
	background-repeat: no-repeat;
	background-position: center center;
	width: 18px;
	height: 18px;

	&.sdi-18px::before {
		font-size: 18px !important;
		background-size: 18px 18px !important;
		width: 18px !important;
		height: 18px !important;
		line-height: 18px;
	}
	&.sdi-20px::before {
		font-size: 20px !important;
		background-size: 20px 20px !important;
		background-repeat: no-repeat;
		background-position: center center;
		width: 20px !important;
		height: 20px !important;
		line-height: 20px;
	}

	&.sdi-22px::before {
		font-size: 22px !important;
		background-size: 22px 22px !important;
		background-position: center center;
		background-repeat: no-repeat;
		width: 22px !important;
		height: 22px !important;
		line-height: 22px;
	}

	&.sdi-40px::before {
		font-size: 40px !important;
		background-size: 40px 40px !important;
		background-position: center center;
		background-repeat: no-repeat;
		width: 40px !important;
		height: 40px !important;
		line-height: 40px;
	}
	&.sdi-grayscale::before {
		filter: grayscale(100%);
	}
}
