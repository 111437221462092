//
// general.scss
//

html {
	position: relative;
	min-height: 100%;
}

body {
	overflow-x: hidden;
	overflow-y: hidden;
	max-height: 100vh;
	font-weight: $font-weight-normal;
	line-height: 1.5;
	font-family: $font-family-base;
	// scrollbar-color: $secondary transparent;
	// scrollbar-width: none;
}

/* width */
::-webkit-scrollbar {
	width: 7px;
	height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
	box-shadow: inset 0 0 7px $dark-900;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: lighten($secondary, 10%);
	border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
	background: $primary;
}

.content-container {
	position: relative;
	top: 0;
	overflow-x: hidden;
	overflow-y: auto;
	height: calc(100vh - #{$topbar-height});
	padding: 0px;
	margin-right: 0px;
	scrollbar-color: $secondary transparent;
	scrollbar-width: none;
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
	body {
		cursor: pointer;
	}
}

.content-container-admin {
	position: relative;
	top: 0;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 0px;
	margin-right: 0px;
	scrollbar-color: $secondary transparent;
	scrollbar-width: none;

	@media (min-width: $lg) {
		height: calc(100vh - #{$topbar-height} - #{$adminbar-height});
	}
	@media (max-width: $lg) {
		height: calc(100vh - #{$topbar-height});
	}
}

.content-container-landing {
	position: relative;
	top: 0;
	overflow-x: hidden;
	overflow-y: auto;
	height: 100vh;
	padding: 0px;
	margin-right: 0px;
	scrollbar-color: $secondary transparent;
	scrollbar-width: thin;
}
.content-container-subscriptions {
	position: relative;
	top: 0;
	overflow-x: hidden;
	overflow-y: auto;
	height: 100vh;
	padding: 0px;
	margin-right: 0px;
	scrollbar-color: $secondary transparent;
	scrollbar-width: thin;
}
