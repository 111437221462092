//
// custom-variables.scss
//
//body bg landing page
$body-bg-public: $dark;

// Shadow
$shadow-light: 0 0 35px 0 rgba($sg-400, 0.5);
$shadow-lg-light: 0 0 45px 0 rgba($sg-500, 0.12);
$shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
$shadow-lg: 0 0 45px 0 rgba(0, 0, 0, 0.12);
$shadow-sm: 0 0 0.3rem 0 rgba(0, 0, 0, 0.12);
$shadow-dark: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.5);
$shadow-alt:
	0 10px 20px rgba(0, 0, 0, 0.19),
	0 6px 6px rgba(0, 0, 0, 0.23);

//Background left-sidebar
$bg-leftbar: linear-gradient(135deg, $dark 0, lighten($dark, 5%) 60%);
$bg-leftbar-light: #ffffff;
$bg-leftbar-dark: linear-gradient(135deg, $dark-n200 0, $dark-100 40%);
$bg-topnav: linear-gradient(135deg, $dark-n200 0, $dark 40%);
$bg-logo: rgba($dark, 0);
// Deatched left sidenav
$bg-detached-leftbar: #37404a;

// Leftbar width
$leftbar-width: 260px;
$leftbar-width-collapsed: 70px;

// Content page
$footer-disabled: true;

// Logo Display
$logo-light-display: block;
$logo-dark-display: none;
// Logo auth display
$logo-auth-light-display: block;
$logo-auth-dark-display: none;

// Boxed Layout
$boxed-layout-width: 1300px;
$boxed-layout-bg: #3e4853;

// Topbar Height
$topbar-height: 70px;

$adminbar-height: 55px;

// Menu item colors (Default-dark)
$menu-item: $sg-400;
$menu-item-hover: $white;
$menu-item-active: $white;
$menu-item-hover-bg: $dark-400;

// Menu item colors (light)
$menu-item-light: #6c757d;
$menu-item-light-hover: $blue;
$menu-item-light-active: $blue;
$menu-item-light-hover-bg: $dark-400;

// Detached Menu item colors
$menu-item-dark: #8391a2;
$menu-item-dark-hover: $blue;
$menu-item-dark-active: $blue;
$menu-item-dark-hover-bg: $dark-400;

// Dark sidenav Menu item color
$sidebar-dark-menu-item: $sg-500;
$sidebar-dark-menu-item-hover: $snow;
$sidebar-dark-menu-item-active: $snow;

// Rightbar
$rightbar-width: 400px;
$rightbar-bg: $dark-300;
$rightbar-title-bg: $dark-400;
$rightbar-title-color: #ffffff;
$rightbar-title-btn-bg: lighten($rightbar-title-bg, 5%);
$rightbar-title-btn-color: #ffffff;
$rightbar-overlay-bg: #000;

// Topbar Background
$bg-topbar: $primary;
$bg-topbar-dark: $dark-100;

// Topbar Search
$bg-topbar-search: $dark-n300;
$bg-topbar-dark-search: $dark-n300;

// Helpbox
$help-box-light-bg: rgba(255, 255, 255, 0.1);
$help-box-dark-bg: #727cf5;

// Font weight
$font-weight-semibold: 500;
$font-weight-normal: 400;

// Dropdown Large
$dropdown-lg-width: 320px;

// Page title color
$page-title-color: $sg-500;

// Header title
$header-title-color: #ffffff;

// Nav-pill background
$nav-pills-bg: $sg-200;

// Custom-accordion
$custom-accordion-title-color: #8391a2;

// Dragula demo background
$dragula-bg: $sg-200;

// Form wizard header background
$form-wizard-header-bg: $sg-200;

// Custom title color
$text-title-color: #ffffff;

// Card loader background
$card-loader-bg: #313a46;

// Chat widget
$chat-primary-user-bg: $sg-200;
$chat-secondary-user-bg: $sg-300;

// User authentication Background
$auth-bg: $dark-200;
$auth-bg-pattern-img: url("https://saxoncircle.blob.core.windows.net/login-page/pattern.svg");

$auth-bg-diamonds: url("data:image/svg+xml,%3Csvg width='48' height='64' viewBox='0 0 48 64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M48 28v-4L36 12 24 24 12 12 0 24v4l4 4-4 4v4l12 12 12-12 12 12 12-12v-4l-4-4 4-4zM8 32l-6-6 10-10 10 10-6 6 6 6-10 10L2 38l6-6zm12 0l4-4 4 4-4 4-4-4zm12 0l-6-6 10-10 10 10-6 6 6 6-10 10-10-10 6-6zM0 16L10 6 4 0h4l4 4 4-4h4l-6 6 10 10L34 6l-6-6h4l4 4 4-4h4l-6 6 10 10v4L36 8 24 20 12 8 0 20v-4zm0 32l10 10-6 6h4l4-4 4 4h4l-6-6 10-10 10 10-6 6h4l4-4 4 4h4l-6-6 10-10v-4L36 56 24 44 12 56 0 44v4z' fill='%2390a5c0' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
$auth-bg-hexagons: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%2390a5c0' fill-opacity='0.4' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
$auth-bg-circles: url("data:image/svg+xml,%3Csvg width='20' height='12' viewBox='0 0 20 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 12c0-.622-.095-1.221-.27-1.785A5.982 5.982 0 0 0 10 12c1.67 0 3.182-.683 4.27-1.785A5.998 5.998 0 0 0 14 12h2a4 4 0 0 1 4-4V6c-1.67 0-3.182.683-4.27 1.785C15.905 7.22 16 6.622 16 6c0-.622-.095-1.221-.27-1.785A5.982 5.982 0 0 0 20 6V4a4 4 0 0 1-4-4h-2c0 .622.095 1.221.27 1.785A5.982 5.982 0 0 0 10 0C8.33 0 6.818.683 5.73 1.785 5.905 1.22 6 .622 6 0H4a4 4 0 0 1-4 4v2c1.67 0 3.182.683 4.27 1.785A5.998 5.998 0 0 1 4 6c0-.622.095-1.221.27-1.785A5.982 5.982 0 0 1 0 6v2a4 4 0 0 1 4 4h2zm-4 0a2 2 0 0 0-2-2v2h2zm16 0a2 2 0 0 1 2-2v2h-2zM0 2a2 2 0 0 0 2-2H0v2zm20 0a2 2 0 0 1-2-2h2v2zm-10 8a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' fill='%2390a5c0' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
$auth-bg-nodes: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='52' height='52' viewBox='0 0 52 52'%3E%3Cpath fill='%2390a5c0' fill-opacity='0.4' d='M0 17.83V0h17.83a3 3 0 0 1-5.66 2H5.9A5 5 0 0 1 2 5.9v6.27a3 3 0 0 1-2 5.66zm0 18.34a3 3 0 0 1 2 5.66v6.27A5 5 0 0 1 5.9 52h6.27a3 3 0 0 1 5.66 0H0V36.17zM36.17 52a3 3 0 0 1 5.66 0h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 0 1 0-5.66V52H36.17zM0 31.93v-9.78a5 5 0 0 1 3.8.72l4.43-4.43a3 3 0 1 1 1.42 1.41L5.2 24.28a5 5 0 0 1 0 5.52l4.44 4.43a3 3 0 1 1-1.42 1.42L3.8 31.2a5 5 0 0 1-3.8.72zm52-14.1a3 3 0 0 1 0-5.66V5.9A5 5 0 0 1 48.1 2h-6.27a3 3 0 0 1-5.66-2H52v17.83zm0 14.1a4.97 4.97 0 0 1-1.72-.72l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1 0-5.52l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43c.53-.35 1.12-.6 1.72-.72v9.78zM22.15 0h9.78a5 5 0 0 1-.72 3.8l4.44 4.43a3 3 0 1 1-1.42 1.42L29.8 5.2a5 5 0 0 1-5.52 0l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1-.72-3.8zm0 52c.13-.6.37-1.19.72-1.72l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43a5 5 0 0 1 5.52 0l4.43-4.43a3 3 0 1 1 1.42 1.41l-4.44 4.43c.36.53.6 1.12.72 1.72h-9.78zm9.75-24a5 5 0 0 1-3.9 3.9v6.27a3 3 0 1 1-2 0V31.9a5 5 0 0 1-3.9-3.9h-6.27a3 3 0 1 1 0-2h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 1 1 2 0v6.27a5 5 0 0 1 3.9 3.9h6.27a3 3 0 1 1 0 2H31.9z'%3E%3C/path%3E%3C/svg%3E");

$bg-pattern-diamonds: $auth-bg-diamonds;
$bg-pattern-hexagons: $auth-bg-hexagons;
$bg-pattern-circles: $auth-bg-circles;
$bg-pattern-nodes: $auth-bg-nodes;

// Apex chart
$apex-grid-color: $sg-200;

// Hero
// $hero-bg: linear-gradient(to bottom, #697ded, #5e30c1);
$hero-bg: linear-gradient(to bottom, $blue, #244693);

//Switch
$switch-bg: $blue-light;

$valid: $success;
$invalid: $danger;

// Highcarts

$highcharts-colors: (
	"0": $primary,
	"1": $success,
	"2": $info-alt,
	"3": $danger,
	"4": $warning,
	"5": $tertiary,
	"6": $light,
	"7": $snow,
	"8": $secondary,
	"9": lighten($primary, 10%),
	"10": $blue-light,
);

$chart-bg: $dark-300;

//React DatePicker

$datepicker__header-color: $sg-500;
$datepicker__selected-color: $primary;
$datepicker__text-color: $sg-500;
$datepicker__muted-color: $light;
$datepicker__highlighted-color: $secondary;
$datepicker__body-background-color: $dark-500;
$datepicker__border-color: $dark-700;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%);
$datepicker__background-color: $dark-700;

$datepicker__border-radius: 0.3rem;
$datepicker__day-margin: 0.166rem;
$datepicker__font-size: 0.8rem;
$datepicker__font-family: $font-family-base;
$datepicker__item-size: 1.7rem;
$datepicker__margin: 0.4rem;
$datepicker__navigation-size: 0.45rem;
$datepicker__triangle-size: 8px;

// additional
$scoreHigh: $green;
$scoreMid: $cyan;
$scoreLow: $dark-pink;

$img-skewed-rotate-y: 35deg;
$img-skewed-rotate-x: 15deg;

$box-shadow-lift:
	0 1rem 2.5rem fade-out($black, 0.9),
	0 0.5rem 1rem -0.75rem fade-out($black, 0.9);
$box-shadow-lift-lg:
	0 2rem 5rem fade-out($black, 0.9),
	0 0.5rem 1rem -0.75rem fade-out($black, 0.95);

$text-selection-bg: $turquoise;
$text-selection-color: $dark-n900;

// Custom CSS Variables

$css-variables: (
	"topbar-height": $topbar-height,
	"admin-topbar-height": 55px,
	"page-title-height": 80px,
	"body-bg": $body-bg,
);
