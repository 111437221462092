//
// dropzone.scss
//

.dropzone {
	border: 2px dashed $input-border-color;
	background: $input-bg;
	border-radius: 6px;
	cursor: pointer;
	min-height: 150px;
	padding: 20px;
	&:hover {
		border: 2px dashed $ice;
	}
	.dz-message {
		text-align: center;
		margin: 2rem 0;
	}
	&.dz-started {
		.dz-message {
			display: none;
		}
	}
}
