// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// https://primer.github.io/.
//

.custom-select {
    display: inline-block;
    width: 100%;
    height: $custom-select-height;
    padding: $custom-select-padding-y ($custom-select-padding-x + $custom-select-indicator-padding) $custom-select-padding-y $custom-select-padding-x;
    font-family: $custom-select-font-family;
    @include font-size($custom-select-font-size);
    font-weight: $custom-select-font-weight;
    line-height: $custom-select-line-height;
    color: $custom-select-color;
    vertical-align: middle;
    background: $custom-select-bg $custom-select-background;
    border: $custom-select-border-width solid $custom-select-border-color;
    @include border-radius($custom-select-border-radius, 0);
    @include box-shadow($custom-select-box-shadow);
    appearance: none;
  
    &:focus {
      border-color: $custom-select-focus-border-color;
      outline: 0;
      @if $enable-shadows {
        @include box-shadow($custom-select-box-shadow, $custom-select-focus-box-shadow);
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: $custom-select-focus-box-shadow;
      }
  
      &::-ms-value {
        // For visual consistency with other platforms/browsers,
        // suppress the default white text on blue background highlight given to
        // the selected option text when the (still closed) <select> receives focus
        // in IE and (under certain conditions) Edge.
        // See https://github.com/twbs/bootstrap/issues/19398.
        color: $input-color;
        background-color: $input-bg;
      }
    }
  
    &[multiple],
    &[size]:not([size="1"]) {
      height: auto;
      padding-right: $custom-select-padding-x;
      background-image: none;
    }
  
    &:disabled {
      color: $custom-select-disabled-color !important;
      background-color: $custom-select-disabled-bg !important;
    }
  
    // Hides the default caret in IE11
    &::-ms-expand {
      display: none;
    }
  
    // Remove outline from select box in FF
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $custom-select-color;
    }
  }
  
  .custom-select-sm {
    height: $custom-select-height-sm;
    padding-top: $custom-select-padding-y-sm;
    padding-bottom: $custom-select-padding-y-sm;
    padding-left: $custom-select-padding-x-sm;
    @include font-size($custom-select-font-size-sm);
  }
  
  .custom-select-lg {
    height: $custom-select-height-lg;
    padding-top: $custom-select-padding-y-lg;
    padding-bottom: $custom-select-padding-y-lg;
    padding-left: $custom-select-padding-x-lg;
    @include font-size($custom-select-font-size-lg);
  }
  


// select.classic {
//     background-image: linear-gradient(
//         45deg,
//         var(--slategray) 50%,
//         var(--primary) 50%
//       ),
//       linear-gradient(135deg, $primary 50%, $slategray 50%),
//       linear-gradient(to right, $primary 50% , $slategray 50%);
//     background-position: calc(100% - 20px) calc(1em + 2px),
//       calc(100% - 15px) calc(1em + 2px), 100% 0;
//     background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
//     background-repeat: no-repeat;
//   }
  
//   select.classic:active {
//     background-image: linear-gradient(
//         45deg,
//         $primary 50%,
//         $primary 50%
//       ),
//       linear-gradient(135deg, $primary 50%, $primary 50%),
//       linear-gradient(to right, $primary 50%, $primary 50%);
//     background-position: calc(100% - 20px) calc(1em + 2px),
//       calc(100% - 15px) calc(1em + 2px), 100% 0;
//     background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
//     background-repeat: no-repeat;
//   }
//   select.classic:focus {
//     background-image: linear-gradient(45deg, $primary 50%, white 50%),
//       linear-gradient(135deg, white 50%, $primary 50%),
//       linear-gradient(to right, $primary, $primary);
//     background-position: calc(100% - 20px) calc(1em + 2px),
//       calc(100% - 15px) calc(1em + 2px), 100% 0;
//     background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
//     background-repeat: no-repeat;
//     border-color: $primary;
//     outline: 0;
//   }
  
//   select.classic:focus-within {
//     background-image: linear-gradient(45deg, white 50%, $primary 50%),
//       linear-gradient(135deg, $primary 50%, white 50%),
//       linear-gradient(to right, $primary, $primary);
//     background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, 100% 0;
//     background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
//     background-repeat: no-repeat;
//     border-color: var(--primary);
//     outline: 0;
//   }
  
//   #assetclass > option {
//     line-height: normal;
//     font-size: 1.2em;
//     margin-top: 0.2em;
//     margin-bottom: 0.2em;
//   }
//   #assetclass > option:hover {
//     line-height: normal;
//     font-size: 1.2em;
//     margin-top: 0.2em;
//     margin-bottom: 0.2em;
//   }
//   #fundingcurrency > option {
//     line-height: normal;
//     font-size: 1.2em;
//     margin-top: 0.2em;
//     margin-bottom: 0.2em;
//   }
//   #fundingcurrency > option:hover {
//     line-height: normal;
//     font-size: 1.2em;
//     margin-top: 0.2em;
//     margin-bottom: 0.2em;
//   }
//   #fundingtarget {
//     border: var(--primary) solid thin;
//   }
//   #fundingtarget:focus-within {
//     border: var(--primary) solid thin;
//   }