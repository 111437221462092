.is-loading {
  background: linear-gradient(110deg, var(--dark) 28%, var(--dark-500) 38%, var(--dark) 53%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
