$escrow-Url: "../fonts/escrow.woff";
$escrow-Url2: "../fonts/escrow.woff2";
$escrow-light-Url: "../fonts/Escrow-Light.woff";
$escrow-light-Url2: "../fonts/Escrow-Light.woff2";

@font-face {
  font-family: "Escrow";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url($escrow-Url2) format("woff2"), url($escrow-Url) format("woff");
}

@font-face {
  font-family: "Escrow";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url($escrow-Url2) format("woff2"), url($escrow-Url) format("woff");
}

@font-face {
  font-family: "Escrow";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url($escrow-Url2) format("woff2"), url($escrow-Url) format("woff");
}

@font-face {
  font-family: "Escrow";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url($escrow-Url2) format("woff2"), url($escrow-Url) format("woff");
}

@font-face {
  font-family: "Escrow";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url($escrow-Url2) format("woff2"), url($escrow-Url) format("woff");
}

@font-face {
  font-family: "Escrow Light";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url($escrow-light-Url2) format("woff2"), url($escrow-light-Url) format("woff");
}

@font-face {
  font-family: "Escrow Light";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url($escrow-light-Url2) format("woff2"), url($escrow-light-Url) format("woff");
}

@font-face {
  font-family: "Escrow Light";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url($escrow-light-Url2) format("woff2"), url($escrow-light-Url) format("woff");
}

@font-face {
  font-family: "Escrow Light";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url($escrow-light-Url2) format("woff2"), url($escrow-light-Url) format("woff");
}

@font-face {
  font-family: "Escrow Light";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url($escrow-light-Url2) format("woff2"), url($escrow-light-Url) format("woff");
}
