// Background lighten

@each $color, $value in $theme-colors {
	.bg-#{$color}-lighten {
		@include bg-variant-light($value);
	}

	.bg-#{$color}-darken {
		@include bg-variant-dark($value);
	}

	.bg-#{$color} {
		@each $op-name, $op-value in $opacity-values {
			&.bg-opacity-#{$op-name} {
				@include bg-opacity($value, $op-value);
			}
		}
	}

	.bg-#{$color}-hover {
		will-change: auto;
		transition: all 0.5s ease-in-out 0s;
		&:hover {
			background-color: $value !important;

			// transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
		}
	}

	.bg-#{$color}-lighten-hover {
		will-change: auto;
		transition: background-color 0.5s ease-in-out 0s;
		&:hover {
			@include bg-variant-light($value);
			will-change: auto;
			transition: background-color 0.5s ease-in-out 0s;
		}
	}

	.bg-#{$color}-darken-hover {
		will-change: auto;
		transition: background-color 0.5s ease-in-out 0s;
		&:hover {
			@include bg-variant-dark($value);
		}
	}

	.bg-#{$color}-grow-hover {
		box-sizing: border-box;
		color: $value;
		box-shadow: none;
		background: transparent;
		position: relative;
		z-index: 1;
		overflow: hidden;
		&:hover {
			color: $dark;
			box-shadow: none;
		}
		&::after {
			content: "";
			box-shadow: none;
			background: $value;
			position: absolute;
			z-index: -1;
			display: block;
			transition: all 0.4s ease;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			transform: scale(0, 0);
		}
		&:hover::after {
			transition: all 0.4s ease-out;
			transform: scale(1, 1);
		}
	}
}

@each $color, $value in $dark-shades {
	.bg-#{$color} {
		background-color: $value !important;
		will-change: auto;
		transition: background-color 0.5s ease 0s;
	}

	.bg-#{$color}-lighten {
		@include bg-variant-light($value);
	}

	.bg-#{$color}-darken {
		@include bg-variant-dark($value);
	}

	.bg-#{$color}-hover {
		will-change: auto;
		transition: background-color 0.5s ease 0s;
		&:hover {
			will-change: auto;
			transition: background-color 0.5s ease 0s;
			background-color: $value !important;
		}
	}

	.bg-#{$color}-lighten-hover {
		will-change: auto;
		transition: background-color 0.3s ease 0s;
		&:hover {
			will-change: auto;
			transition: background-color 0.3s ease 0s;
			@include bg-variant-light($value);
		}
	}

	.bg-#{$color}-darken-hover {
		&:hover {
			@include bg-variant-dark($value);
			will-change: auto;
			transition: background-color 0.3s ease 0s;
		}
	}
}

@each $color, $value in $slategray-shades {
	.bg-#{$color} {
		background-color: $value !important;
	}

	.bg-#{$color}-lighten {
		@include bg-variant-light($value);
	}

	.bg-#{$color}-darken {
		@include bg-variant-dark($value);
	}

	.bg-#{$color}-hover {
		&:hover {
			background-color: $value !important;
		}
	}

	.bg-#{$color}-lighten-hover {
		&:hover {
			@include bg-variant-light($value);
			will-change: "auto";
			transition: "all 0.3s ease 0s";
		}
	}

	.bg-#{$color}-darken-hover {
		&:hover {
			@include bg-variant-dark($value);
			will-change: "auto";
			transition: "all 0.3s ease 0s";
		}
	}
}

.bg-dark-gradient-divider {
	background-image: linear-gradient(to right, transparent, $dark-150, $dark-700, $dark-150, transparent);
	margin: 1rem 0;
}
.bg-divider {
	background: transparent;
	position: relative;
	&::before {
		position: absolute;
		left: 10%;
		width: 80%;
		top: 0;
		content: "";
		background-image: linear-gradient(to right, transparent, $dark-150, $dark-700, $dark-150, transparent);
		height: 0.1rem;
	}
	// margin: 1rem 0;
}
.bg-dark-gradient-v {
	// background-color: lighten($value, 10%);
	background: linear-gradient(180deg, $dark-200, $dark-n300) !important;
}
.bg-dark-gradient-v-reversed {
	// background-color: lighten($value, 10%);
	background: linear-gradient(0deg, $dark-200, $dark-n300) !important;
}

@each $name, $value in $theme-colors {
	.bg-#{$name}-gradient {
		background-color: lighten($value, 10%);
		background: linear-gradient(
			135deg,
			rgba(lighten($value, 10%), 1),
			rgba(lighten($value, 8%), 1),
			rgba(lighten($value, 6%), 1),
			rgba(lighten($value, 4%), 1),
			rgba(lighten($value, 2%), 1),
			rgba(lighten($value, 1%), 1),
			rgba(lighten($value, 2%), 1),
			rgba(lighten($value, 4%), 1),
			rgba(lighten($value, 6%), 1),
			rgba(lighten($value, 8%), 1),
			rgba(lighten($value, 10%), 1)
		) !important;
	}

	.bg-#{$name}-gradient-alt {
		background-color: lighten($value, 20%);
		background: linear-gradient(135deg, lighten($value, 1%) 0, lighten($value, 7%) 40%) !important;
	}
	.bg-#{$name}-gradient-1 {
		background-color: lighten($value, 20%);
		background: linear-gradient(135deg, lighten($value, 1%) 0, lighten($value, 7%) 100%) !important;
	}
	.bg-#{$name}-gradient-2 {
		background-color: lighten($value, 20%);
		background: linear-gradient(135deg, lighten($value, 3%) 0, lighten($value, 7%) 100%) !important;
	}
	.bg-#{$name}-gradient-3 {
		background-color: $value;
		background: linear-gradient(135deg, lighten($value, 3%) 0, darken($value, 5%) 50%, lighten($value, 3%) 100%) !important;
	}
	.bg-#{$name}-gradient-4 {
		background-color: $value;
		background: linear-gradient(135deg, darken($value, 1%) 0, lighten($value, 7%) 40%, darken($value, 1%) 100%) !important;
	}
	// .bg-#{$name}-gradient-5 {
	//   background-color: lighten($value, 20%);
	//   background: linear-gradient(135deg, darken($value, 1%) 0, lighten($value, 7%) 100%) !important;
	// }
}

@each $name, $value in $dark-shades {
	.bg-#{$name}-gradient {
		background-color: lighten($value, 10%);
		background: linear-gradient(
			135deg,
			rgba(lighten($value, 10%), 1),
			rgba(lighten($value, 8%), 1),
			rgba(lighten($value, 6%), 1),
			rgba(lighten($value, 4%), 1),
			rgba(lighten($value, 2%), 1),
			rgba(lighten($value, 1%), 1),
			rgba(lighten($value, 2%), 1),
			rgba(lighten($value, 4%), 1),
			rgba(lighten($value, 6%), 1),
			rgba(lighten($value, 8%), 1),
			rgba(lighten($value, 10%), 1)
		) !important;
	}

	.bg-#{$name}-gradient-alt {
		background-color: lighten($value, 20%);
		background: linear-gradient(135deg, lighten($value, 1%) 0, lighten($value, 7%) 40%) !important;
	}
	.bg-#{$name}-gradient-1 {
		background-color: lighten($value, 20%);
		background: linear-gradient(135deg, lighten($value, 1%) 0, lighten($value, 7%) 100%) !important;
	}
	.bg-#{$name}-gradient-2 {
		background-color: lighten($value, 20%);
		background: linear-gradient(135deg, lighten($value, 3%) 0, lighten($value, 7%) 100%) !important;
	}
	.bg-#{$name}-gradient-3 {
		background-color: lighten($value, 20%);
		background: linear-gradient(135deg, lighten($value, 5%) 0, lighten($value, 10%) 100%) !important;
	}
	.bg-#{$name}-gradient-4 {
		background-color: $value;
		background: linear-gradient(135deg, darken($value, 1%) 0, lighten($value, 7%) 40%, darken($value, 1%) 100%) !important;
	}
}

.bg-dark-primary-gradient {
	background-color: lighten($primary, 10%);
	background: linear-gradient(71deg, $dark-n200, hsla(203, 100%, 17%, 0.5), $dark-n200);
}

.bg-upload {
	// width: 100%;
	// min-height: 100%;
	background-color: transparent;
	position: relative;
	z-index: 0;
	overflow: hidden;

	&:hover::before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: 400%;
		/*we keep 0deg in the gradient*/
		background-image: repeating-linear-gradient(90deg, transparent, transparent 1%, rgba(255, 255, 255, 5%) 1%, rgba(255, 255, 255, 5%) 2%);
		transform: skewX(135deg);
		transform-origin: bottom right;
		animation: loading-slide 30s linear infinite;
	}
}

@keyframes loading-slide {
	to {
		transform: translateX(50%) skewX(135deg);
	}
}

.bg-pattern-diamonds {
	position: relative;
	&:before {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-image: $bg-pattern-diamonds;
		background-size: 2%;
		background-position: center;
		background-repeat: repeat;
		opacity: 0.5;
		content: " ";
	}
	:first-child {
		position: relative;
	}
}
.bg-pattern-hexagons {
	position: relative;
	&:before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-image: $bg-pattern-hexagons;
		background-size: 1%;
		background-position: center;
		background-repeat: repeat;
		opacity: 0.5;
	}
	:first-child {
		position: relative;
	}
}
.bg-pattern-dots {
	// background-color: $dark-n200;
	// background-image: linear-gradient(180deg, transparent 0, darken($dark, 5%) 50%, transparent 100%),
	// 	url(https://res.cloudinary.com/saxonadvisors/image/upload/v1649198255/landing/saxon-nodes/pattern-2_v7dgmg.png);
	background-image: linear-gradient(180deg, transparent, darken($dark, 5%) 50%, transparent 100%),
		url(https://res.cloudinary.com/saxonadvisors/image/upload/v1671119078/landing/general/paterrn-3_tfwiin.svg);
}
.bg-pattern-dots-2 {
	// background-color: $dark-n200;
	// background-image: linear-gradient(180deg, darken($dark, 5%) 0, $dark 50%, transparent 100%),
	// 	url(https://res.cloudinary.com/saxonadvisors/image/upload/v1649198255/landing/saxon-nodes/pattern-2_v7dgmg.png);
	background-image: linear-gradient(180deg, darken($dark, 5%) 0, $dark 50%, transparent 100%),
		url(https://res.cloudinary.com/saxonadvisors/image/upload/v1671119078/landing/general/paterrn-3_tfwiin.svg);
}

.bg-pattern-dots-3 {
	// background-color: $dark-n200;
	// background-image: linear-gradient(180deg, $dark-n400, $dark 50%, transparent 100%), url(https://res.cloudinary.com/saxonadvisors/image/upload/v1649198255/landing/saxon-nodes/pattern-2_v7dgmg.png);
	background-image: linear-gradient(180deg, $dark-n400, $dark 50%, transparent 100%), url(https://res.cloudinary.com/saxonadvisors/image/upload/v1671119078/landing/general/paterrn-3_tfwiin.svg);
}
.bg-pattern-dots-3-reversed {
	// background-color: $dark-n200;
	background-image: linear-gradient(0deg, $dark-n400 0, $dark 50%, transparent 100%), url(https://res.cloudinary.com/saxonadvisors/image/upload/v1649198255/landing/saxon-nodes/pattern-2_v7dgmg.png);
}
.bg-pattern-dots-4 {
	// background-color: $dark-n200;
	background-image: url(https://res.cloudinary.com/saxonadvisors/image/upload/v1649198255/landing/saxon-nodes/pattern-2_v7dgmg.png);
}
.bg-pattern-circles {
	position: relative;
	&:before {
		content: " ";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-image: $auth-bg-circles;
		background-size: 2%;
		background-position: center;
		background-repeat: repeat;
		opacity: 0.5;
	}
	:first-child {
		position: relative;
	}
}
.bg-pattern-nodes {
	position: relative;
	&:before {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-image: $auth-bg-nodes;
		background-size: 2%;
		content: "";
		background-position: center;
		background-repeat: repeat;
		opacity: 0.5;
	}
	:first-child {
		position: relative;
	}
}
.bg-gradient-radial-sm {
	min-width: 376px;
	background: radial-gradient(
		ellipse farthest-side at center center,
		rgba(142, 164, 255, 0.2),
		rgba(193, 205, 255, 0.1) 25%,
		rgba(193, 205, 255, 0.05) 50%,
		rgba(142, 164, 255, 0.02) 75%,
		transparent 100%
	);
}

.bg-gradient-radial {
	min-width: 576px;
	background: radial-gradient(
		ellipse farthest-side at center center,
		rgba(142, 164, 255, 0.2),
		rgba(193, 205, 255, 0.1) 25%,
		rgba(193, 205, 255, 0.05) 50%,
		rgba(142, 164, 255, 0.02) 75%,
		transparent 100%
	);
}
