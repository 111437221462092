.swap-img {
	&div {
		filter: drop-shadow(0 0 0.75rem #000);
	}

	.white-img {
		padding: 5px;
		border-radius: 50%;
		-webkit-filter: url(#monochrome);
		filter: url(#monochrome);
	}
}
.swapsInputWrapper {
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type="number"] {
		-moz-appearance: textfield;
	}

	.errorTooltip {
		position: relative;
		z-index: 5;
		float: right;
		margin-right: 10px;
		margin-top: calc(#{$input-height-lg / $input-line-height-lg} * -1);
		line-height: $input-line-height-lg;
	}
}

#paytokenBtn {
	&::after {
		display: none;
	}
}
