  :root {
    --reach-combobox: 1;    
    
  }

  
  [data-reach-combobox-popover] {    
    z-index: 1000;
    border: solid 0px $dark-900;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;    
    background: $dark-300;
    font-size: 100%;    
  }
  
  [data-reach-combobox-list] {
    list-style: none;    
    margin: 0;
    padding: 0;
    user-select: none;    
  }
  
  [data-reach-combobox-option] {
    cursor: pointer;
    margin: 0;    
    width: 100%;
    padding: 0.375rem 1.5rem;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;    
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;    
    clear: both;
    font-weight: 300 !important;
    color:$light !important;
    text-align: inherit;
    white-space: nowrap;
    background: transparent;

    // border: solid 2px transparent;
  }
  
  [data-reach-combobox-option][aria-selected="true"] {
    background: $dark-600;
  }
  
  [data-reach-combobox-option]:hover {
    background: $dark-900;
  }
  
  [data-reach-combobox-option][aria-selected="true"]:hover {
    background:$dark-900;
  }
  
  [data-user-value] {
    color: $light !important;
    font-weight: 500;
  }
  [data-suggested-value] {    
    color: $light !important;
    font-weight: 500;
  }
  

