@use "sass:math";

@for $i from 0 through 10 {
	$name: ($i * 10);
	$value: percentage(math.div(1, $i));

	.opacity-#{$name} {
		opacity: $value !important;
	}

	.opacity-#{$name}-hover {
		&:hover {
			opacity: $value !important;
		}
	}
}
