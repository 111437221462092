// 
// pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
  .page-link {
    border-radius: 30px !important;
    margin: 0 3px;
    border: none;
  }
}
.paginate_button {
  &.page-item{
    .page-link {
      border-radius: 30px !important;
      margin: 0 3px;
      border: none;
      color: $light;
      background-color: $secondary;
      &:hover {
        color: $snow;
        background-color: $primary;
      }
    }
    &.active{
      .page-link {
        color: $snow;
        background-color: darken($primary,10%);
        &:hover{
          color: $snow;
          background-color: $primary;
        }
      }
    }
    &.disabled{
      .page-link {
        color: darken($light,10%);
        background-color: darken($secondary,10%);
      }
    }
  }
}