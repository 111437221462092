@use "sass:color";
@use "sass:math";
// Variables

//
// Color system
//

//colors

$white: #eef2f7;

$sg: #2c3a4b;
$sg-100: #2c3a4d;
$sg-200: #334359;
$sg-300: #3a4d66;
$sg-400: #90a5c0;
$sg-450: #a2bad9;
$sg-500: #c1cddc;

$lightgrayblue: #758db0;

$slategray-shades: (
	"sg": $sg,
	"sg-100": $sg-100,
	"sg-200": $sg-200,
	"sg-300": $sg-300,
	"sg-400": $sg-400,
	"sg-450": $sg-450,
	"sg-500": $sg-500,
);

// // stylelint-disable
$gray-100: #404954;
$gray-200: #37404a;
$gray-300: #47505c;
$gray-400: #8391a2;
$gray-500: #aab8c5;
$gray-600: #ced4da;
$gray-700: #dee2e6;
$gray-800: #e3eaef;
$gray-900: #f1f1f1;

$grays: ();
$grays: map-merge(
	(
		"100": $gray-100,
		"200": $gray-200,
		"300": $gray-300,
		"400": $gray-400,
		"500": $gray-500,
		"600": $gray-600,
		"700": $gray-700,
		"800": $gray-800,
		"900": $gray-900,
	),
	$grays
);

$white: #ebf6ff;

$blue: #1aabff;
$indigo: #727cf5;
$purple: #6b5eae;
$pink: #ff679b;
$dark-pink: #ff4f95;
$red: #fa5c7c;
$orange: #fd7e14;
$yellow: #ffbc00;
$green: #52e3c2;
$teal: #06d7e7;
$cyan: #40c4ff;
$turquoise: #42c3ff;
$darkblue: #152337;
$black: #0a1929;
$navy: #102030;
$mui-blue-main: #90caf9;
$blue-light: #99d1ff;
$ocean: #5ba1e7;

// fusv-disable
$blue-400: lighten($blue, 20%);
$blue-300: lighten($blue, 15%);
$blue-200: lighten($blue, 10%);
$blue-100: lighten($blue, 5%);
$blue-n100: darken($blue, 5%);
$blue-n200: darken($blue, 10%);
$blue-n300: darken($blue, 15%);
$blue-n400: darken($blue, 20%);

$indigo-100: tint-color($indigo, 80%);
$indigo-200: tint-color($indigo, 60%);
$indigo-300: tint-color($indigo, 40%);
$indigo-400: tint-color($indigo, 20%);
$indigo-500: $indigo;
$indigo-600: shade-color($indigo, 20%);
$indigo-700: shade-color($indigo, 40%);
$indigo-800: shade-color($indigo, 60%);
$indigo-900: shade-color($indigo, 80%);

$purple-100: tint-color($purple, 80%);
$purple-200: tint-color($purple, 60%);
$purple-300: tint-color($purple, 40%);
$purple-400: tint-color($purple, 20%);
$purple-500: $purple;
$purple-600: shade-color($purple, 20%);
$purple-700: shade-color($purple, 40%);
$purple-800: shade-color($purple, 60%);
$purple-900: shade-color($purple, 80%);

$pink-100: tint-color($pink, 80%);
$pink-200: tint-color($pink, 60%);
$pink-300: tint-color($pink, 40%);
$pink-400: tint-color($pink, 20%);
$pink-500: $pink;
$pink-600: shade-color($pink, 20%);
$pink-700: shade-color($pink, 40%);
$pink-800: shade-color($pink, 60%);
$pink-900: shade-color($pink, 80%);

$red-100: tint-color($red, 80%);
$red-200: tint-color($red, 60%);
$red-300: tint-color($red, 40%);
$red-400: tint-color($red, 20%);
$red-500: $red;
$red-600: shade-color($red, 20%);
$red-700: shade-color($red, 40%);
$red-800: shade-color($red, 60%);
$red-900: shade-color($red, 80%);

$orange-100: tint-color($orange, 80%);
$orange-200: tint-color($orange, 60%);
$orange-300: tint-color($orange, 40%);
$orange-400: tint-color($orange, 20%);
$orange-500: $orange;
$orange-600: shade-color($orange, 20%);
$orange-700: shade-color($orange, 40%);
$orange-800: shade-color($orange, 60%);
$orange-900: shade-color($orange, 80%);

$yellow-100: tint-color($yellow, 80%);
$yellow-200: tint-color($yellow, 60%);
$yellow-300: tint-color($yellow, 40%);
$yellow-400: tint-color($yellow, 20%);
$yellow-500: $yellow;
$yellow-600: shade-color($yellow, 20%);
$yellow-700: shade-color($yellow, 40%);
$yellow-800: shade-color($yellow, 60%);
$yellow-900: shade-color($yellow, 80%);

$green-100: tint-color($green, 80%);
$green-200: tint-color($green, 60%);
$green-300: tint-color($green, 40%);
$green-400: tint-color($green, 20%);
$green-500: $green;
$green-600: shade-color($green, 20%);
$green-700: shade-color($green, 40%);
$green-800: shade-color($green, 60%);
$green-900: shade-color($green, 80%);

$teal-100: tint-color($teal, 80%);
$teal-200: tint-color($teal, 60%);
$teal-300: tint-color($teal, 40%);
$teal-400: tint-color($teal, 20%);
$teal-500: $teal;
$teal-600: shade-color($teal, 20%);
$teal-700: shade-color($teal, 40%);
$teal-800: shade-color($teal, 60%);
$teal-900: shade-color($teal, 80%);

$cyan-100: tint-color($cyan, 80%);
$cyan-200: tint-color($cyan, 60%);
$cyan-300: tint-color($cyan, 40%);
$cyan-400: tint-color($cyan, 20%);
$cyan-500: $cyan;
$cyan-600: shade-color($cyan, 20%);
$cyan-700: shade-color($cyan, 40%);
$cyan-800: shade-color($cyan, 60%);
$cyan-900: shade-color($cyan, 80%);

$dark-100: lighten($navy, 2%);
$dark-150: lighten($navy, 3%);
$dark-200: lighten($navy, 4%);
$dark-300: lighten($navy, 5%);
$dark-400: lighten($navy, 6%);
$dark-500: lighten($navy, 10%);
$dark-600: lighten($navy, 12%);
$dark-700: lighten($navy, 14%);
$dark-800: lighten($navy, 16%);
$dark-900: lighten($navy, 18%);
$dark-n900: darken($navy, 9%);
$dark-n800: darken($navy, 8%);
$dark-n700: darken($navy, 7%);
$dark-n600: darken($navy, 6%);
$dark-n500: darken($navy, 5%);
$dark-n400: darken($navy, 4%);
$dark-n300: darken($navy, 3%);
$dark-n200: darken($navy, 2%);
$dark-n150: darken($navy, 1.5%);
$dark-n100: darken($navy, 1%);

$dark-shades: (
	"dark-n100": $dark-n100,
	"dark-n200": $dark-n200,
	"dark-n300": $dark-n300,
	"dark-n400": $dark-n400,
	"dark-n500": $dark-n500,
	"dark-n600": $dark-n600,
	"dark-n700": $dark-n600,
	"dark-n800": $dark-n800,
	"dark-n900": $dark-n900,
	"dark-100": $dark-100,
	"dark-150": $dark-150,
	"dark-200": $dark-200,
	"dark-300": $dark-300,
	"dark-400": $dark-400,
	"dark-500": $dark-500,
	"dark-600": $dark-600,
	"dark-700": $dark-700,
	"dark-800": $dark-800,
	"dark-900": $dark-900,
);

$sgb-500: color.scale(#c1cddc, $blue: 100%);
$sgb-400: darken($sgb-500, 2%);
$sgb-300: darken($sgb-500, 4%);
$sgb-200: darken($sgb-500, 6%);
$sgb-100: darken($sgb-500, 8%);
$sgb: darken($sgb-500, 10%);

$colors: ();
$colors: map-merge(
	(
		"blue": $blue,
		"blue-light": $blue-light,
		"indigo": $indigo,
		"purple": $purple,
		"pink": $pink,
		"dark-pink": $dark-pink,
		"red": $red,
		"orange": $orange,
		"yellow": $yellow,
		"green": $green,
		"teal": $teal,
		"cyan": $cyan,
		"turquoise": $turquoise,
		"white": $white,
		"gray": $sg-100,
		"gray-dark": $gray-800,
		"dark-blue": $darkblue,
		"navy": $navy,
		"light-100": $gray-800,
		"light-200": $gray-700,
		"light-300": $gray-600,
		"light-400": $gray-500,
		"dark-100": $dark-100,
		"dark-150": $dark-150,
		"dark-200": $dark-200,
		"dark-300": $dark-300,
		"dark-400": $dark-400,
		"dark-500": $dark-500,
		"dark-600": $dark-600,
		"dark-700": $dark-700,
		"dark-800": $dark-800,
		"dark-900": $dark-900,
		"dark-n100": $dark-n100,
		"dark-n150": $dark-n150,
		"dark-n200": $dark-n200,
		"dark-n300": $dark-n300,
		"dark-n400": $dark-n400,
		"dark-n500": $dark-n500,
		"dark-n600": $dark-n600,
		"dark-n700": $dark-n700,
		"dark-n800": $dark-n800,
		"dark-n900": $dark-n900,
		"sg": $sg,
		"sg-100": $sg-100,
		"sg-200": $sg-200,
		"sg-300": $sg-300,
		"sg-400": $sg-400,
		"sg-500": $sg-500,
		"blue-n100": $blue-n100,
		"blue-n200": $blue-n200,
		"blue-n300": $blue-n300,
		"blue-n400": $blue-n400,
		"blue-100": $blue-100,
		"blue-200": $blue-200,
		"blue-300": $blue-300,
		"blue-400": $blue-400,
	),
	$colors
);

$primary: $blue;
$secondary: $sg-300;
$success: $green;
$info: $cyan;
$info-alt: $turquoise;
$warning: $yellow;
$danger: $pink;
$danger-alt: $dark-pink;
$light: $sg-450;
$dark: $navy;
$tertiary: $indigo;
$snow: $white;
$ice: $sg-500;
$water: $blue-light;
$sleet: $mui-blue-main;

$theme-colors: ();
$theme-colors: map-merge(
	(
		"primary": $primary,
		"secondary": $secondary,
		"snow": $snow,
		"ice": $ice,
		"sleet": $sleet,
		"water": $water,
		"ocean": $ocean,
		"light": $light,
		"dark": $dark,
		"success": $success,
		"info": $info,
		"warning": $warning,
		"danger": $danger,
		"tertiary": $tertiary,
		"info-alt": $info-alt,
		"danger-alt": $danger-alt,
	),
	$theme-colors
);

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 1.8;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $gray-200;
$color-contrast-light: $white;

$opacity-values: (
	10: 0.1,
	25: 0.25,
	50: 0.5,
	75: 0.75,
	100: 1,
);
