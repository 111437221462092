$text-lighten-values: ("1" 2%, "2" 4%, "3" 6%, "4" 8%, "5" 10%);
//
// text.scss
//

// Weight

.font-family-serif {
	font-family: $font-family-serif !important;
}

.font-weight-semibold {
	font-weight: $font-weight-semibold !important;
}

.font-weight-thin {
	font-weight: $font-weight-thin !important;
}

.ff-header {
	font-family: "Inter", sans-serif;
	font-weight: 700;
	font-style: normal;
	text-transform: capitalize;
	letter-spacing: 0.04em;
}

// Font Family

.ff-escrow {
	font-family: "Escrow" !important;
}

.ff-escrow-light {
	font-family: "Escrow Light" !important;
}

.ff-serif {
	font-family: $font-family-serif !important;
}

.ff-sans-serif {
	font-family: $font-family-sans-serif !important;
}

.ff-sans-serif-alt {
	font-family: $font-family-sans-serif-alt !important;
	letter-spacing: 3px;
}

.ff-logo {
	font-family: $font-family-logo !important;
	letter-spacing: 3px;
	font-weight: 700;
	text-transform: uppercase;
}

.ff-monospace {
	font-family: $font-family-monospace !important;
}

@for $i from 1 through 9 {
	$name: ($i * 100);
	// .fw-#{$name}-alt {
	//   font-weight: $name !important;
	//   font-family: inherit;
	// }

	.fw-#{$name} {
		font-weight: $name !important;
		font-family: $font-family-sans-serif;
		// font-family: initial;
	}
	.fw-#{$name}-inter {
		font-weight: $name !important;
		font-family: "Inter", sans-serif !important;
	}
	.fw-#{$name}-escrow {
		font-weight: $name !important;
		font-family: "Escrow", serif !important;
	}
	.fw-#{$name}-escrow-light {
		font-weight: $name !important;
		font-family: "Escrow Light", serif !important;
	}
}

@each $name, $value in $slategray-shades {
	.text-#{$name} {
		color: $value !important;
	}
}

@each $name, $value in $dark-shades {
	.text-#{$name} {
		color: $value !important;
	}
}

@each $color, $value in $theme-colors {
	.text-#{$color} {
		color: ($value) !important;
	}
	.text-#{$color}-lighten {
		color: lighten($value, 5%) !important;
	}

	@each $name, $percent in $text-lighten-values {
		.text-#{$color}-lighten-#{$name} {
			color: lighten($value, $percent) !important;
		}
	}

	.text-#{$color}-active {
		&:active {
			will-change: auto;
			transition: color 0.3s ease 0s;
			color: $value !important;
		}
	}
	.text-#{$color}-hover {
		&:hover {
			will-change: auto;
			transition: color 0.3s ease 0s;
			color: $value !important;
		}
	}
	.text-#{$color}-lighten-hover {
		&:hover {
			will-change: auto;
			transition: color 0.3s ease 0s;
			color: lighten($value, 15%) !important;
		}
	}
	.text-#{$color}-darken-hover {
		&:hover {
			will-change: auto;
			transition: color 0.3s ease 0s;
			color: darken($value, 15%) !important;
		}
	}
	.text-#{$color}-focus {
		&:focus {
			will-change: auto;
			transition: color 0.3s ease 0s;
			color: $value !important;
		}
	}
	.text-#{$color}-lighten-focus {
		&:focus {
			color: lighten($value, 2%) !important;
			will-change: auto;
			transition: color 0.3s ease 0s;
		}
	}

	.gradient-text-#{$color} {
		background-image: linear-gradient(to right, $value, hsl(227, 55%, 35%)) !important;
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}
