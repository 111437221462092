.upload-options {
	position: relative;
	height: 50px;
	background-color: $light;
	cursor: pointer;
	overflow: hidden;
	text-align: center;
	transition: background-color ease-in-out 150ms;
	&:hover {
		background-color: darken($light, 10%);
	}
	& input {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}
	& label {
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
		font-weight: 400;
		text-overflow: ellipsis;
		white-space: nowrap;
		cursor: pointer;
		overflow: hidden;
		&::after {
			content: "\F0415";
			font-family: "Material Design Icons";
			position: absolute;
			font-size: 2rem;
			color: $dark-300;
			top: calc(50% - 1.35rem);
			left: calc(50% - 1.25rem);
			z-index: 0;
		}
		& span {
			display: inline-block;
			width: 50%;
			height: 100%;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			vertical-align: middle;
			text-align: center;
			&:hover i.mdi {
				color: $sg-500;
			}
		}
	}
}

.js--image-preview {
	height: 225px;
	width: 100%;
	position: relative;
	overflow: hidden;
	// background-image: url('');
	background-color: $sg-300;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	&::after {
		content: "\F02E9";
		font-family: "Material Design Icons";
		position: relative;
		font-size: 4.5em;
		color: $light;
		top: calc(50% - 3rem);
		left: calc(50% - 2.25rem);
		z-index: 0;
	}
	&.js--no-default::after {
		display: none;
	}
	&:nth-child(2) {
		background-image: url("http://bastianandre.at/giphy.gif");
	}
}

.drop {
	display: block;
	position: absolute;
	background: transparentize($info-alt, 0.8);
	border-radius: 100%;
	transform: scale(0);
}

.animate {
	animation: ripple 0.4s linear;
}

@keyframes ripple {
	100% {
		opacity: 0;
		transform: scale(2.5);
	}
}
