.defs-only {
    position: absolute;
    height: 0;
    width: 0;
    overflow: none;
    left: -100%;
}

.monochrome-img {    
    -webkit-filter: url(#monochrome);
    filter: url(#monochrome);
}
.bw-img {    
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);;
}
