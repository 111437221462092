@import "../../config/variables.scss";

.score10 {
  background-color: lighten($scoreHigh, 15%);
  color: $dark;
}

.score9 {
  background-color: lighten($scoreHigh, 10%);
  color: $dark;
}

.score8 {
  background-color: lighten($scoreHigh, 5%);
  color: $light;
}

.score7 {
  background-color: lighten($scoreHigh, 0%);
  color: $dark;
}

.score6 {
  background-color: lighten($scoreMid, 5%);
  color: $dark;
}

.score5 {
  background-color: $scoreMid;
  color: $dark;
}

.score4 {
  background-color: darken($scoreMid, 5%);
  color: $dark;
}
.score3 {
  background-color: lighten($scoreLow, 0%);
  color: $dark;
}

.score2 {
  background-color: lighten($scoreLow, 5%);
  color: $dark;
}

.score1 {
  background-color: lighten($scoreLow, 10%);
  color: $dark;
}

.text-score10 {
  color: lighten($scoreHigh, 15%);
}

.text-score9 {
  color: lighten($scoreHigh, 10%);
}

.text-score8 {
  color: lighten($scoreHigh, 5%);
}

.text-score7 {
  color: lighten($scoreHigh, 0%);
}

.text-score6 {
  color: lighten($scoreMid, 5%);
}

.text-score5 {
  color: lighten($scoreMid, 0%);
}

.text-score4 {
  color: darken($scoreMid, 5%);
}

.text-score3 {
  color: lighten($scoreLow, 0%);
}

.text-score2 {
  color: lighten($scoreLow, 5%);
}

.text-score1 {
  color: lighten($scoreLow, 10%);
}

table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

table.dataTable td,
table.dataTable th {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
  text-align: center;
}

table.dataTable.nowrap th,
table.dataTable.nowrap td {
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length select {
  width: auto;
  display: inline-block;
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}

div.dataTables_wrapper div.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}

div.dataTables_wrapper div.dataTables_info {
  padding-top: 0.85em;
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
}

div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 1em 0;
}

table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 30px;
}

table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 1em;
  content: "\2191";
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "\2193";
}

table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
  opacity: 1;
}

table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  opacity: 0;
}

div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody table thead .sorting:before,
div.dataTables_scrollBody table thead .sorting_asc:before,
div.dataTables_scrollBody table thead .sorting_desc:before,
div.dataTables_scrollBody table thead .sorting:after,
div.dataTables_scrollBody table thead .sorting_asc:after,
div.dataTables_scrollBody table thead .sorting_desc:after {
  display: none;
}

div.dataTables_scrollBody table tbody tr:first-child th,
div.dataTables_scrollBody table tbody tr:first-child td {
  border-top: none;
}

div.dataTables_scrollFoot > .dataTables_scrollFootInner {
  box-sizing: content-box;
}

div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
  margin-top: 0 !important;
  border-top: none;
}

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length,
  div.dataTables_wrapper div.dataTables_filter,
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }
}

table.dataTable.table-sm > thead > tr > th {
  padding-right: 20px;
}

table.dataTable.table-sm .sorting:before,
table.dataTable.table-sm .sorting_asc:before,
table.dataTable.table-sm .sorting_desc:before {
  top: 5px;
  right: 0.85em;
}

table.dataTable.table-sm .sorting:after,
table.dataTable.table-sm .sorting_asc:after,
table.dataTable.table-sm .sorting_desc:after {
  top: 5px;
}

table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
  border-left-width: 0;
}

table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
  border-right-width: 0;
}

table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
  border-bottom-width: 0;
}

div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:first-child {
  padding-left: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:last-child {
  padding-right: 0;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.child,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.child,
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty {
  cursor: default !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty:before {
  display: none !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child,
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child {
  position: relative;
  padding-left: 40px;
  padding-top: 24px;
  cursor: pointer;
}

table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child:before {
  top: 15px !important;
  left: 28px;
  height: 28px;
  width: 28px;
  display: block;
  position: absolute;
  color: $light;
  border: 0px solid white;
  border-radius: 28px;
  box-shadow: 0 0 3px #444;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  line-height: 28px;
  font-size: 22px;
  font-family: "Material Design Icons";
  content: "\F0142";
  background-color: transparent !important;
}
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child::before,
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child::before {
  top: 15px !important;
  left: 28px;
  height: 28px;
  width: 28px;
  display: block;
  position: absolute;
  color: $light;
  border: 0px solid white;
  border-radius: 28px;
  box-shadow: 0 0 3px #444;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  line-height: 28px;
  font-size: 22px;
  font-family: "Material Design Icons";
  content: "\F0142";
  background-color: transparent !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child::before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child::before {
  color: $light;
  font-family: "Material Design Icons";
  content: "\F0140";
  background-color: transparent !important;
}
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  color: $light;
  font-family: "Material Design Icons";
  content: "\F0140";
  background-color: transparent !important;
}

table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child,
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child {
  padding-left: 27px;
}

table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child:before {
  top: 5px;
  left: 4px;
  height: 14px;
  width: 14px;
  border-radius: 14px;
  line-height: 14px;
  text-indent: 3px;
}

table.dataTable.dtr-column > tbody > tr > td.control,
table.dataTable.dtr-column > tbody > tr > th.control {
  position: relative;
  cursor: pointer;
}

table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before {
  top: 15px !important;
  left: 28px;
  height: 28px;
  width: 28px;
  display: block;
  position: absolute;
  color: $light;
  border: 0px solid white;
  border-radius: 28px;
  box-shadow: 0 0 3px #444;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  line-height: 28px;
  font-size: 22px;
  font-family: "Material Design Icons";
  content: "\F0142" !important;
  background-color: transparent !important;
}

table.dataTable.dtr-column > tbody > tr.parent td.control:before,
table.dataTable.dtr-column > tbody > tr.parent th.control:before {
  font-family: "Material Design Icons";
  content: "\F0140" !important;
  background-color: transparent !important;
}

table.dataTable > tbody > tr.child {
  padding: 0.5em 1em;
}

table.dataTable > tbody > tr.child:hover {
  background: transparent !important;
}

table.dataTable > tbody > tr.child ul.dtr-details {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

table.dataTable > tbody > tr.child ul.dtr-details > li {
  border-bottom: 1px solid $dark-500;
  padding: 0.5em 0;
}

table.dataTable > tbody > tr.child ul.dtr-details > li:first-child {
  padding-top: 0;
}

table.dataTable > tbody > tr.child ul.dtr-details > li:last-child {
  border-bottom: none;
}

table.dataTable > tbody > tr.child span.dtr-title {
  display: inline-block;
  min-width: 75px;
  font-weight: bold;
}

div.dtr-modal {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  padding: 10em 1em;
}

div.dtr-modal div.dtr-modal-display {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 50%;
  overflow: auto;
  margin: auto;
  z-index: 102;
  overflow: auto;
  background-color: #f5f5f7;
  border: 1px solid black;
  border-radius: 0.5em;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6);
}

div.dtr-modal div.dtr-modal-content {
  position: relative;
  padding: 1em;
}

div.dtr-modal div.dtr-modal-close {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 22px;
  height: 22px;
  border: 1px solid #eaeaea;
  background-color: #f9f9f9;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  z-index: 12;
}

div.dtr-modal div.dtr-modal-close:hover {
  background-color: #eaeaea;
}

div.dtr-modal div.dtr-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 767px) {
  div.dtr-modal div.dtr-modal-display {
    width: 95%;
  }
}

div.dtr-bs-modal table.table tr:first-child td {
  border-top: none;
}
