.deals-container{
    position: relative;
    max-height: calc(100vh - #{$topbar-height} - 2px);
    // max-height: calc(100vh - #{$topbar-height} - 77px - 2px);
    overflow-x: hidden    
}
.deals-container-parent{
    position: relative;
    max-height: calc(100vh - #{$topbar-height} - 1px);
    min-height: calc(100vh - #{$topbar-height} -  1px);
    // max-height: calc(100vh - #{$topbar-height} - 77px - 1px);
    // min-height: calc(100vh - #{$topbar-height} - 77px - 1px);
    overflow: hidden;
    
}