//
// type.scss
//

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 10px 0;
	font-weight: $font-weight-semibold;
}

::selection {
	background: $text-selection-bg;
	color: $text-selection-color;
}

// Font sizes (Custom)

.font-10 {
	font-size: 10px !important;
}

.font-11 {
	font-size: 11px !important;
}

.font-12 {
	font-size: 12px !important;
}

.font-13 {
	font-size: 13px !important;
}

.font-14 {
	font-size: 14px !important;
}

.font-15 {
	font-size: 15px !important;
}

.font-16 {
	font-size: 16px !important;
}

.font-18 {
	font-size: 18px !important;
}

.font-20 {
	font-size: 20px !important;
}

.font-22 {
	font-size: 22px !important;
}

.font-24 {
	font-size: 24px !important;
}

.font-36 {
	font-size: 36px !important;
}

.font-48 {
	font-size: 48px !important;
}
