.modal{
  & select {
    width: 300px;
    padding: 12px;
    border: 1px solid $primary!important;
    background-color: $sg-300;
    /* needed */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* SVG background image */
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
      background-size: .6em;
      background-position: calc(100% - 1.3em) center;
      background-repeat: no-repeat;
  }
}
select::-ms-expand {
    display: none;
}


select.classic {
  display: inline-block;
  width: 100%;
  height: $custom-select-height;
  padding: $custom-select-padding-y ($custom-select-padding-x + $custom-select-indicator-padding) $custom-select-padding-y $custom-select-padding-x;
  font-family: $custom-select-font-family;
  @include font-size($custom-select-font-size);
  font-weight: $custom-select-font-weight;
  line-height: $custom-select-line-height;
  color: $custom-select-color;
  vertical-align: middle;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: calc(100% - 20px) calc(1em + 2px),
  calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  background: $custom-select-bg $custom-select-background;
  border: $custom-select-border-width solid $custom-select-border-color;
  @include border-radius($custom-select-border-radius, 0);
  @include box-shadow($custom-select-box-shadow);
  appearance: none;

  &:focus {
    border-color: $custom-select-focus-border-color;
    outline: 0;
    @if $enable-shadows {
      @include box-shadow($custom-select-box-shadow, $custom-select-focus-box-shadow);
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: $custom-select-focus-box-shadow;
    }

    &::-ms-value {
      // For visual consistency with other platforms/browsers,
      // suppress the default white text on blue background highlight given to
      // the selected option text when the (still closed) <select> receives focus
      // in IE and (under certain conditions) Edge.
      // See https://github.com/twbs/bootstrap/issues/19398.
      color: $input-color;
      background-color: $input-bg;
    }
  }

  &[multiple],
  &[size]:not([size="1"]) {
    height: auto;
    padding-right: $custom-select-padding-x;
    background-image: none;
  }

  &:disabled {
    color: $custom-select-disabled-color;
    background-color: $custom-select-disabled-bg;
  }

  // Hides the default caret in IE11
  &::-ms-expand {
    display: none;
  }

  // Remove outline from select box in FF
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $custom-select-color;
  }
}

select.classic-sm {
  height: $custom-select-height-sm;
  padding-top: $custom-select-padding-y-sm;
  padding-bottom: $custom-select-padding-y-sm;
  padding-left: $custom-select-padding-x-sm;
  @include font-size($custom-select-font-size-sm);
}

select.classic-lg {
  height: $custom-select-height-lg;
  padding-top: $custom-select-padding-y-lg;
  padding-bottom: $custom-select-padding-y-lg;
  padding-left: $custom-select-padding-x-lg;
  @include font-size($custom-select-font-size-lg);
}

#assetclass > option {
  line-height: normal;
  font-size: 1.2em;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}
#assetclass > option:hover {
  line-height: normal;
  font-size: 1.2em;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}
#fundingcurrency > option {
  line-height: normal;
  font-size: 1.2em;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}
#fundingcurrency > option:hover {
  line-height: normal;
  font-size: 1.2em;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}
#fundingtarget {
  border: $primary solid thin;
}
#fundingtarget:focus-within {
  border: $primary solid thin;
}



select.classic:active {
  background-image: linear-gradient(
      45deg,
        $sg-100 50%,
        $primary 50%
      ),
      linear-gradient(135deg, $primary 50%, $sg-100 50%),
      linear-gradient(to right, $sg-100 50%, $sg-100 50%);
    background-position: calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px), 100% 0;
        
  }


select.classic:focus {
  background-image: linear-gradient(45deg, $primary 50%, white 50%),
    linear-gradient(135deg, white 50%, $primary 50%),
    linear-gradient(to right, $primary, $primary);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;    
  border-color: $primary;  
}

select.classic:focus-within {
  background-image: linear-gradient(45deg, white 50%, $primary 50%),
    linear-gradient(135deg, $primary 50%, white 50%),
    linear-gradient(to right, $primary, $primary);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;  
  border-color: $primary;  
}