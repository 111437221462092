// DatePicker Mixer

%triangle-arrow {
    margin-left: -$datepicker__triangle-size;
    position: absolute;

    &,
    &::before {
        box-sizing: content-box;
        position: absolute;
        border: $datepicker__triangle-size solid transparent;

        height: 0;
        width: 1px;
    }

    &::before {
        content: "";
        z-index: -1;
        border-width: $datepicker__triangle-size;

        left: -$datepicker__triangle-size;
        border-bottom-color: $datepicker__border-color;
    }
}

%triangle-arrow-up {
    @extend %triangle-arrow;

    top: 0;
    margin-top: -$datepicker__triangle-size;

    &,
    &::before {
        border-top: none;
        border-bottom-color: $datepicker__background-color;
    }

    &::before {
        top: -1px;
        border-bottom-color: $datepicker__border-color;
    }
}

%triangle-arrow-down {
    @extend %triangle-arrow;

    bottom: 0;
    margin-bottom: -$datepicker__triangle-size;

    &,
    &::before {
        border-bottom: none;
        border-top-color: #fff;
    }

    &::before {
        bottom: -1px;
        border-top-color: $datepicker__border-color;
    }
}
