.rotate-hover {
	.rotate {
		transform: rotate(0turn) !important;
		transition: transform 0.5s ease-in-out !important;
	}
	&:hover {
		.rotate {
			transform: rotate(1turn) !important;
			transition: transform 0.5s ease-in-out !important;
		}
	}
}
