.ql-container {
	box-sizing: border-box;
	font-family: var(--font-family-sans-serif);
	font-size: $font-size-base;
	font-weight: $font-weight-normal;
	line-height: 1.6em;
	// height: 100%;
	margin: 0px;
	position: relative;
}
.ql-container.ql-disabled .ql-tooltip {
	visibility: hidden;
}
.ql-container:not(.ql-disabled) li[data-list="checked"] > .ql-ui,
.ql-container:not(.ql-disabled) li[data-list="unchecked"] > .ql-ui {
	cursor: pointer;
}
.ql-clipboard {
	left: -100000px;
	height: 1px;
	overflow-y: hidden;
	position: absolute;
	top: 50%;
}
.ql-clipboard p {
	margin: 0;
	padding: 0;
}
.ql-editor {
	box-sizing: border-box;
	counter-reset: list-0;
	line-height: 1.6em;
	height: 100%;
	outline: none;
	overflow-y: auto;
	padding: 10px 10px;
	-moz-tab-size: 4;
	tab-size: 4;
	text-align: left;
	white-space: pre-wrap;
	word-wrap: break-word;
}
.ql-editor > * {
	cursor: text;
}
.ql-editor p,
.ql-editor ol,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
	line-height: 1.6em;
	margin-left: 0em;
	margin-right: 0em;
	margin-top: 0em;
	margin-bottom: 0em;
	padding: 0em 0em 0em 0em;
}
.ql-editor p,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
	counter-reset: list-0 list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor table {
	border-collapse: collapse;
}
.ql-editor p {
	margin: 0em;
}
.ql-editor td {
	border: 1px solid var(--snow);
	padding: 2px 5px;
}
.ql-editor ol {
	padding-left: 1.5em;
}
.ql-editor li {
	list-style-type: none;
	padding-left: 1.5em;
	position: relative;
}
.ql-editor li > .ql-ui:before {
	display: inline-block;
	margin-left: -1.5em;
	margin-right: 0.3em;
	text-align: right;
	white-space: nowrap;
	width: 1.2em;
}
.ql-editor li[data-list="checked"] > .ql-ui,
.ql-editor li[data-list="unchecked"] > .ql-ui {
	color: $gray-400;
}
.ql-editor li[data-list="bullet"] > .ql-ui:before {
	content: "\2022";
}
.ql-editor li[data-list="checked"] > .ql-ui:before {
	content: "\2611";
}
.ql-editor li[data-list="unchecked"] > .ql-ui:before {
	content: "\2610";
}
.ql-editor li[data-list="ordered"] {
	counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
	counter-increment: list-0;
}
.ql-editor li[data-list="ordered"] > .ql-ui:before {
	content: counter(list-0, decimal) ". ";
}
.ql-editor li[data-list="ordered"].ql-indent-1 {
	counter-increment: list-1;
}
.ql-editor li[data-list="ordered"].ql-indent-1 > .ql-ui:before {
	content: counter(list-1, lower-alpha) ". ";
}
.ql-editor li[data-list="ordered"].ql-indent-1 {
	counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor li[data-list="ordered"].ql-indent-2 {
	counter-increment: list-2;
}
.ql-editor li[data-list="ordered"].ql-indent-2 > .ql-ui:before {
	content: counter(list-2, lower-roman) ". ";
}
.ql-editor li[data-list="ordered"].ql-indent-2 {
	counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor li[data-list="ordered"].ql-indent-3 {
	counter-increment: list-3;
}
.ql-editor li[data-list="ordered"].ql-indent-3 > .ql-ui:before {
	content: counter(list-3, decimal) ". ";
}
.ql-editor li[data-list="ordered"].ql-indent-3 {
	counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor li[data-list="ordered"].ql-indent-4 {
	counter-increment: list-4;
}
.ql-editor li[data-list="ordered"].ql-indent-4 > .ql-ui:before {
	content: counter(list-4, lower-alpha) ". ";
}
.ql-editor li[data-list="ordered"].ql-indent-4 {
	counter-reset: list-5 list-6 list-7 list-8 list-9;
}
.ql-editor li[data-list="ordered"].ql-indent-5 {
	counter-increment: list-5;
}
.ql-editor li[data-list="ordered"].ql-indent-5 > .ql-ui:before {
	content: counter(list-5, lower-roman) ". ";
}
.ql-editor li[data-list="ordered"].ql-indent-5 {
	counter-reset: list-6 list-7 list-8 list-9;
}
.ql-editor li[data-list="ordered"].ql-indent-6 {
	counter-increment: list-6;
}
.ql-editor li[data-list="ordered"].ql-indent-6 > .ql-ui:before {
	content: counter(list-6, decimal) ". ";
}
.ql-editor li[data-list="ordered"].ql-indent-6 {
	counter-reset: list-7 list-8 list-9;
}
.ql-editor li[data-list="ordered"].ql-indent-7 {
	counter-increment: list-7;
}
.ql-editor li[data-list="ordered"].ql-indent-7 > .ql-ui:before {
	content: counter(list-7, lower-alpha) ". ";
}
.ql-editor li[data-list="ordered"].ql-indent-7 {
	counter-reset: list-8 list-9;
}
.ql-editor li[data-list="ordered"].ql-indent-8 {
	counter-increment: list-8;
}
.ql-editor li[data-list="ordered"].ql-indent-8 > .ql-ui:before {
	content: counter(list-8, lower-roman) ". ";
}
.ql-editor li[data-list="ordered"].ql-indent-8 {
	counter-reset: list-9;
}
.ql-editor li[data-list="ordered"].ql-indent-9 {
	counter-increment: list-9;
}
.ql-editor li[data-list="ordered"].ql-indent-9 > .ql-ui:before {
	content: counter(list-9, decimal) ". ";
}
.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
	padding-left: 3em;
}
.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
	padding-left: 4.5em;
}
.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
	padding-right: 3em;
}
.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
	padding-right: 4.5em;
}
.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
	padding-left: 6em;
}
.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
	padding-left: 7.5em;
}
.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
	padding-right: 6em;
}
.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
	padding-right: 7.5em;
}
.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
	padding-left: 9em;
}
.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
	padding-left: 10.5em;
}
.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
	padding-right: 9em;
}
.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
	padding-right: 10.5em;
}
.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
	padding-left: 12em;
}
.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
	padding-left: 13.5em;
}
.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
	padding-right: 12em;
}
.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
	padding-right: 13.5em;
}
.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
	padding-left: 15em;
}
.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
	padding-left: 16.5em;
}
.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
	padding-right: 15em;
}
.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
	padding-right: 16.5em;
}
.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
	padding-left: 18em;
}
.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
	padding-left: 19.5em;
}
.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
	padding-right: 18em;
}
.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
	padding-right: 19.5em;
}
.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
	padding-left: 21em;
}
.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
	padding-left: 22.5em;
}
.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
	padding-right: 21em;
}
.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
	padding-right: 22.5em;
}
.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
	padding-left: 24em;
}
.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
	padding-left: 25.5em;
}
.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
	padding-right: 24em;
}
.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
	padding-right: 25.5em;
}
.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
	padding-left: 27em;
}
.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
	padding-left: 28.5em;
}
.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
	padding-right: 27em;
}
.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
	padding-right: 28.5em;
}
.ql-editor li.ql-direction-rtl {
	padding-right: 1.5em;
}
.ql-editor li.ql-direction-rtl > .ql-ui:before {
	margin-left: 0.3em;
	margin-right: -1.5em;
	text-align: left;
}
.ql-editor table {
	table-layout: fixed;
	width: 100%;
}
.ql-editor table td {
	outline: none;
}
.ql-editor .ql-code-block-container {
	font-family: monospace;
}
.ql-editor .ql-video {
	display: block;
	max-width: 100%;
}
.ql-editor .ql-video.ql-align-center {
	margin: 0 auto;
}
.ql-editor .ql-video.ql-align-right {
	margin: 0 0 0 auto;
}
.ql-editor .ql-bg-black {
	background-color: var(--dark);
}
.ql-editor .ql-bg-red {
	background-color: var(--danger);
}
.ql-editor .ql-bg-orange {
	background-color: var(--orange);
}
.ql-editor .ql-bg-yellow {
	background-color: var(--warning);
}
.ql-editor .ql-bg-green {
	background-color: var(--success);
}
.ql-editor .ql-bg-blue {
	background-color: var(--primary);
}
.ql-editor .ql-bg-purple {
	background-color: var(--tertiary);
}
.ql-editor .ql-color-white {
	color: var(--snow);
}
.ql-editor .ql-color-red {
	color: var(--danger);
}
.ql-editor .ql-color-orange {
	color: var(--orange);
}
.ql-editor .ql-color-yellow {
	color: var(--warning);
}
.ql-editor .ql-color-green {
	color: var(--success);
}
.ql-editor .ql-color-blue {
	color: var(--primary);
}
.ql-editor .ql-color-purple {
	color: var(--tertiary);
}
.ql-editor .ql-font-serif {
	font-family: $font-family-serif;
}
.ql-editor .ql-font-monospace {
	font-family: var(--font-family-monospace);
}
.ql-editor .ql-size-small {
	font-size: $font-size-base;
}
.ql-editor .ql-size-large {
	font-size: $h2-font-size;
}
.ql-editor .ql-size-huge {
	font-size: $h1-font-size;
}
.ql-editor .ql-direction-rtl {
	direction: rtl;
	text-align: inherit;
}
.ql-editor .ql-align-center {
	text-align: center;
}
.ql-editor .ql-align-justify {
	text-align: justify;
}
.ql-editor .ql-align-right {
	text-align: right;
}
.ql-editor .ql-ui {
	position: absolute;
}
.ql-editor.ql-blank::before {
	color: var(--snow);
	content: attr(data-placeholder);
	font-style: none;
	left: 15px;
	pointer-events: none;
	position: absolute;
	right: 15px;
}

.ql-editor img {
	max-width: 100%;
	height: auto;
	padding-top: 1em;
	padding-bottom: 1em;
	text-align: center;
	display: inline;
}
