$stroke-width: 3px;
$stroke-width-hover: 4px;

.highcharts-graph {
  fill: none;
  stroke-width: $stroke-width;
  stroke-linecap: round;
  stroke-linejoin: round;
  

  &.highcharts-state-hover {
      stroke-width: $stroke-width-hover;
    }
}


@for $i from 0 through 20 {
  $name: ($i * 1);
  $value: ($i * 1px);
    .stroke-w-#{$name} {    
        stroke-width: $value !important;
    }
}