// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

@if $enable-grid-classes {
	// Single container class with breakpoint max-widths
	.container-slim,
  // 100% wide container at all breakpoints
  .container-fluid {
		@include make-container();
	}

	// Responsive containers that are 100% wide until a breakpoint
	// slim container
	@each $breakpoint, $container-max-width in $container-max-widths {
		$slim-container-max-width: $container-max-width * 0.8;
		.container-slim-#{$breakpoint} {
			@extend .container-fluid;
		}

		@include media-breakpoint-up($breakpoint, $grid-breakpoints) {
			%responsive-container-#{$breakpoint} {
				max-width: $slim-container-max-width;
			}

			// Extend each breakpoint which is smaller or equal to the current breakpoint
			$extend-breakpoint: true;

			@each $name, $width in $grid-breakpoints {
				@if ($extend-breakpoint) {
					.container-slim#{breakpoint-infix($name, $grid-breakpoints)} {
						@extend %responsive-container-#{$breakpoint};
					}

					// Once the current breakpoint is reached, stop extending
					@if ($breakpoint == $name) {
						$extend-breakpoint: false;
					}
				}
			}
		}
	}
	// wide container
	@each $breakpoint, $container-max-width in $container-max-widths {
		$wide-container-max-width: $container-max-width * 1.1;
		.container-wide-#{$breakpoint} {
			@extend .container-fluid;
		}

		@include media-breakpoint-up($breakpoint, $grid-breakpoints) {
			%responsive-container-#{$breakpoint} {
				max-width: $wide-container-max-width;
			}

			// Extend each breakpoint which is smaller or equal to the current breakpoint
			$extend-breakpoint: true;

			@each $name, $width in $grid-breakpoints {
				@if ($extend-breakpoint) {
					.container-wide#{breakpoint-infix($name, $grid-breakpoints)} {
						@extend %responsive-container-#{$breakpoint};
					}

					// Once the current breakpoint is reached, stop extending
					@if ($breakpoint == $name) {
						$extend-breakpoint: false;
					}
				}
			}
		}
	}
}
