@use "sass:math";

@each $sizeName,$size in $grid-breakpoints {
    @media (min-width: $size) {
        @for $i from 0 through 20 {
        
            $name: ($i * 5);       
            $value: percentage(math.div(($i * 5), 100));       

            .w-#{$sizeName}-#{$name} {
                min-width: #{$value} !important;
                max-width: #{$value} !important;
                width: #{$value} !important;
            }
            
            .h-#{$sizeName}-#{$name} {
                min-height: #{$value} !important;
                min-height: #{$value} !important;
                height: #{$value} !important;
            }    
                    
        
        }
    }

}    

