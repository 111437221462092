@import url("https://code.highcharts.com/css/highcharts.css");
@import "./elements/graph";
@import "./elements/plot-lines";
@import "./mixins/strokewidth";

.highcharts-container {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
	text-align: left;
	line-height: normal;
	z-index: 0; /* #1072 */
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	font-family: $font-family-base;
	font-size: 12px;
	user-select: none;
}

.highcharts-root {
	display: block;
}

.highcharts-root text {
	color: white;
	stroke-width: 0;
}

.highcharts-strong {
	font-weight: bold;
}

.highcharts-emphasized {
	font-style: italic;
}

.highcharts-anchor {
	cursor: pointer;
}

.highcharts-background {
	fill: $chart-bg;
	stroke: $light;
	stroke-width: 0;
}

.highcharts-plot-border,
.highcharts-plot-background {
	fill: none;
}

.highcharts-label-box {
	fill: none;
}

.highcharts-button-box {
	fill: none;
}

.highcharts-tracker-line {
	stroke-linejoin: round;
	stroke: rgba(192, 192, 192, 0.0001);
	stroke-width: 22;
	fill: none;
}

.highcharts-tracker-area {
	fill: rgba(192, 192, 192, 0.0001);
	stroke-width: 0;
}

/* Titles */
.highcharts-title {
	fill: #ffffff !important;
	margin-bottom: 0.5rem;
	text-transform: uppercase;
	letter-spacing: 0.02em;
	font-size: 0.875rem;
	margin-top: 0;
}

.highcharts-subtitle {
	fill: #ffffff !important;
}

/* Axes */
.highcharts-axis-line {
	fill: none;
	stroke: transparent;
}

.highcharts-yaxis .highcharts-axis-line {
	stroke-width: 0px;
}

.highcharts-axis-title {
	fill: $light;
}

.highcharts-axis-labels {
	fill: $light;
	cursor: default;
	font-size: 0.9em;
}

.highcharts-grid-line {
	fill: none;
	stroke: $dark-500;
}

.highcharts-xaxis-grid .highcharts-grid-line {
	stroke-width: 0px;
}

.highcharts-xaxis-grid .highcharts-grid-line .highcharts-polygon-series {
	stroke-width: 0px;
}

.highcharts-tick {
	stroke: $dark-500;
}

.highcharts-yaxis .highcharts-tick {
	stroke-width: 0px;
}

.highcharts-minor-grid-line {
	stroke: #f2f2f2;
}

.highcharts-crosshair-thin {
	stroke-width: 1px;
	stroke: #cccccc;
}

.highcharts-crosshair-category {
	stroke: #ccd6eb;
	stroke-opacity: 0.25;
}

/* Credits */
.highcharts-credits {
	cursor: pointer;
	fill: #ffffff;
	font-size: 1.4em;
	font-weight: 700;
	letter-spacing: 0.03rem;
	font-family: $font-family-logo;
	transition:
		fill 250ms,
		font-size 250ms;
}

.highcharts-credits:hover {
	fill: $primary;
	font-size: 1.6em;
}

/* Tooltip */
.highcharts-tooltip {
	cursor: default;
	pointer-events: none;
	white-space: nowrap;
	transition: stroke 150ms;
}

.highcharts-tooltip text {
	fill: $light;
}

.highcharts-tooltip .highcharts-header {
	font-size: 1.1;
	font-family: var(--font-family-sans-serif);
}

.highcharts-tooltip-box {
	stroke-width: 1px;
	fill: $dark;
	fill-opacity: 0.95;
}

.highcharts-tooltip-box .highcharts-label-box {
	fill: $dark;
	fill-opacity: 0.95;
}

div.highcharts-tooltip {
	filter: none;
}

.highcharts-selection-marker {
	fill: $primary;
	fill-opacity: 0.25;
}

.highcharts-point-inactive {
	opacity: 0.2;
	transition: opacity 50ms;
	/* quick in */
}

.highcharts-series-inactive {
	opacity: 0.2;
	transition: opacity 50ms;
	/* quick in */
}

.highcharts-state-hover path {
	transition: stroke-width 50ms;
	/* quick in */
}

.highcharts-state-normal path {
	transition: stroke-width 250ms;
	/* slow out */
}

/* Legend hover affects points and series */
g.highcharts-series,
.highcharts-point,
.highcharts-markers,
.highcharts-data-labels {
	transition: opacity 250ms;
}

.highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
.highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
.highcharts-legend-series-active .highcharts-markers:not(.highcharts-series-hover),
.highcharts-legend-series-active .highcharts-data-labels:not(.highcharts-series-hover) {
	opacity: 0.2;
}

.highcharts-markers {
	stroke-width: 1px;
	stroke: #ffffff;
}

.highcharts-point {
	stroke-width: 0.5px;
}

.highcharts-dense-data .highcharts-point {
	stroke-width: 0;
}

.highcharts-data-label {
	font-size: 1em;
	font-weight: bold;
}

.highcharts-data-label-box {
	fill: none;
	stroke-width: 0;
}

.highcharts-data-label text,
text.highcharts-data-label {
	fill: $light;
}
.highcharts-pie-series > .highcharts-data-label text,
text.highcharts-data-label {
	fill: white;
}

.highcharts-data-label-connector {
	fill: none;
}

.highcharts-halo {
	fill-opacity: 0.25;
	stroke-width: 0;
}

.highcharts-series:not(.highcharts-pie-series) .highcharts-point-select,
.highcharts-markers .highcharts-point-select {
	fill: #ffffff;
	stroke: #000000;
}

.highcharts-column-series rect.highcharts-point {
	stroke: inherit;
	stroke-width: 0;
}

.highcharts-column-series .highcharts-point {
	transition: fill-opacity 250ms;
}

.highcharts-column-series .highcharts-point-hover {
	fill-opacity: 0.75;
	transition: fill-opacity 50ms;
}

.highcharts-pie-series .highcharts-point {
	stroke-linejoin: round;
	stroke: transparent;
}

.highcharts-pie-series .highcharts-point-hover {
	fill-opacity: 0.75;
	transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point {
	stroke-linejoin: round;
	stroke: #ffffff;
}

.highcharts-funnel-series .highcharts-point-hover {
	fill-opacity: 0.75;
	transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point-select {
	fill: inherit;
	stroke: inherit;
}

.highcharts-pyramid-series .highcharts-point {
	stroke-linejoin: round;
	stroke: #ffffff;
}

.highcharts-pyramid-series .highcharts-point-hover {
	fill-opacity: 0.75;
	transition: fill-opacity 50ms;
}

.highcharts-pyramid-series .highcharts-point-select {
	fill: inherit;
	stroke: inherit;
}

.highcharts-solidgauge-series .highcharts-point {
	stroke-width: 0;
}

.highcharts-treemap-series .highcharts-point {
	stroke-width: 1px;
	stroke: #e6e6e6;
	transition:
		stroke 250ms,
		fill 250ms,
		fill-opacity 250ms;
}

.highcharts-treemap-series .highcharts-point-hover {
	stroke: #999999;
	transition:
		stroke 25ms,
		fill 25ms,
		fill-opacity 25ms;
}

.highcharts-treemap-series .highcharts-above-level {
	display: none;
}

.highcharts-treemap-series .highcharts-internal-node {
	fill: none;
}

.highcharts-treemap-series .highcharts-internal-node-interactive {
	fill-opacity: 0.15;
	cursor: pointer;
}

.highcharts-treemap-series .highcharts-internal-node-interactive:hover {
	fill-opacity: 0.75;
}

.highcharts-vector-series .highcharts-point {
	fill: none;
	stroke-width: 2px;
}

.highcharts-windbarb-series .highcharts-point {
	fill: none;
	stroke-width: 2px;
}

.highcharts-lollipop-stem {
	stroke: #000000;
}

.highcharts-focus-border {
	border-radius: initial;
	fill: none;
	stroke-width: 0px;
}

.highcharts-legend-item-hidden .highcharts-focus-border {
	fill: none !important;
}

/* Legend */
.highcharts-legend-box {
	fill: none;
	stroke-width: 0;
}

.highcharts-legend-item > text {
	fill: var(--snow);
	font-weight: bold;
	font-size: 1em;
	cursor: pointer;
	stroke-width: 0;
}

.highcharts-legend-item:hover text {
	fill: #ffffff;
}

.highcharts-legend-item-hidden * {
	fill: #ffffff !important;
	stroke: #ffffff !important;
	transition: fill 250ms;
}

.highcharts-legend-nav-active {
	fill: #003399;
	cursor: pointer;
}

.highcharts-legend-nav-inactive {
	fill: #ffffff;
}

circle.highcharts-legend-nav-active,
circle.highcharts-legend-nav-inactive {
	/* tracker */
	fill: rgba(192, 192, 192, 0.0001);
}

.highcharts-legend-title-box {
	fill: none;
	stroke-width: 0;
}

/* Bubble legend */
.highcharts-bubble-legend-symbol {
	stroke-width: 2;
	fill-opacity: 0.5;
}

.highcharts-bubble-legend-connectors {
	stroke-width: 1;
}

.highcharts-bubble-legend-labels {
	fill: $light;
}

/* Loading */
.highcharts-loading {
	position: absolute;
	background-color: #ffffff;
	opacity: 0.5;
	text-align: center;
	z-index: 10;
	transition: opacity 250ms;
}

.highcharts-loading-hidden {
	height: 0 !important;
	opacity: 0;
	overflow: hidden;
	transition:
		opacity 250ms,
		height 250ms step-end;
}

.highcharts-loading-inner {
	font-weight: bold;
	position: relative;
	top: 45%;
}

/* Highcharts More and modules */
.highcharts-boxplot-box {
	fill: #ffffff;
}

.highcharts-boxplot-median {
	stroke-width: 2px;
}

.highcharts-bubble-series .highcharts-point {
	fill-opacity: 0.5;
}

.highcharts-errorbar-series .highcharts-point {
	stroke: #000000;
}

.highcharts-gauge-series .highcharts-data-label-box {
	stroke: #cccccc;
	stroke-width: 1px;
}

.highcharts-gauge-series .highcharts-dial {
	fill: #000000;
	stroke-width: 0;
}

.highcharts-polygon-series .highcharts-graph {
	fill: inherit;
	stroke-width: 0;
}

.highcharts-waterfall-series .highcharts-graph {
	stroke: $light;
	stroke-dasharray: 1, 3;
}

.highcharts-sankey-series .highcharts-point {
	stroke-width: 0;
}

.highcharts-sankey-series .highcharts-link {
	transition:
		fill 250ms,
		fill-opacity 250ms;
	fill-opacity: 0.5;
}

.highcharts-sankey-series .highcharts-point-hover.highcharts-link {
	transition:
		fill 50ms,
		fill-opacity 50ms;
	fill-opacity: 1;
}

.highcharts-venn-series .highcharts-point {
	fill-opacity: 0.75;
	stroke: #cccccc;
	transition:
		stroke 250ms,
		fill-opacity 250ms;
}

.highcharts-venn-series .highcharts-point-hover {
	fill-opacity: 1;
	stroke: #cccccc;
}

/* Highstock */
.highcharts-navigator-mask-outside {
	fill-opacity: 0;
}

.highcharts-navigator-mask-inside {
	fill: #6685c2;
	/* navigator.maskFill option */
	fill-opacity: 0.25;
	cursor: ew-resize;
}

.highcharts-navigator-outline {
	stroke: #cccccc;
	fill: none;
}

.highcharts-navigator-handle {
	stroke: #cccccc;
	fill: #f2f2f2;
	cursor: ew-resize;
}

.highcharts-navigator-series {
	fill: #335cad;
	stroke: #335cad;
}

.highcharts-navigator-series .highcharts-graph {
	stroke-width: 1px;
}

.highcharts-navigator-series .highcharts-area {
	fill-opacity: 0.05;
}

.highcharts-navigator-xaxis .highcharts-axis-line {
	stroke-width: 0;
}

.highcharts-navigator-xaxis .highcharts-grid-line {
	stroke-width: 0px;
	stroke: var(--secondary);
}

.highcharts-navigator-xaxis.highcharts-axis-labels {
	fill: #999999;
}

.highcharts-navigator-yaxis .highcharts-grid-line {
	stroke-width: 0;
}

.highcharts-scrollbar-thumb {
	fill: var(--secondary);
	stroke: var(--secondary);
	stroke-width: 1px;
}

.highcharts-scrollbar-button {
	fill: #e6e6e6;
	stroke: #cccccc;
	stroke-width: 1px;
}

.highcharts-scrollbar-arrow {
	fill: $light;
}

.highcharts-scrollbar-rifles {
	stroke: $light;
	stroke-width: 1px;
}

.highcharts-scrollbar-track {
	fill: #f2f2f2;
	stroke: #f2f2f2;
	stroke-width: 1px;
}

.highcharts-button {
	fill: $dark-800;
	stroke: $dark-900;
	cursor: pointer;
	stroke-width: 0px;
	transition: fill 250ms;
}

.highcharts-button text {
	fill: $light;
}

.highcharts-button-hover {
	transition: fill 0ms;
	fill: #e6e6e6;
	stroke: #cccccc;
}

.highcharts-button-hover text {
	fill: white;
}

.highcharts-button-pressed {
	font-weight: bold;
	fill: $dark-900;
	stroke: $dark-900;
}

.highcharts-button-pressed text {
	fill: white;
	font-weight: bold;
}

.highcharts-button-disabled text {
	fill: $sg-100;
}

.highcharts-range-selector-buttons .highcharts-button {
	stroke-width: 0px;
}
.highcharts-range-selector-group > .highcharts-range-selector-buttons > text {
	fill: transparent;
}

.highcharts-range-label rect {
	fill: none;
}

.highcharts-range-label text {
	fill: $light;
}

.highcharts-range-input rect {
	fill: $dark-700;
}

.highcharts-range-input text {
	fill: $light;
}

.highcharts-range-input {
	stroke-width: 1px;
	stroke: $light;
}

input.highcharts-range-selector {
	position: absolute;
	border: 0px;
	width: 1px;
	/* Chrome needs a pixel to see it */
	height: 1px;
	padding: 0.3em;
	text-align: center;
	left: -9em;
	/* #4798 */
}

.highcharts-crosshair-label text {
	fill: #ffffff;
	font-size: 1.1em;
}

.highcharts-crosshair-label .highcharts-label-box {
	fill: inherit;
}

.highcharts-candlestick-series .highcharts-point {
	stroke: #000000;
	stroke-width: 1px;
}

.highcharts-candlestick-series .highcharts-point-up {
	fill: #ffffff;
}

.highcharts-ohlc-series .highcharts-point-hover {
	stroke-width: 3px;
}

.highcharts-flags-series .highcharts-point .highcharts-label-box {
	stroke: $light;
	fill: #ffffff;
	transition: fill 250ms;
}

.highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
	stroke: #000000;
	fill: #ccd6eb;
}

.highcharts-flags-series .highcharts-point text {
	fill: #000000;
	font-size: 0.9em;
	font-weight: bold;
}

/* Highmaps */
.highcharts-map-series .highcharts-point {
	transition:
		fill 500ms,
		fill-opacity 500ms,
		stroke-width 250ms;
	stroke: $light;
}

.highcharts-map-series .highcharts-point-hover {
	transition:
		fill 0ms,
		fill-opacity 0ms;
	fill-opacity: 0.5;
	stroke-width: 2px;
}

.highcharts-mapline-series .highcharts-point {
	fill: none;
}

.highcharts-heatmap-series .highcharts-point {
	stroke-width: 0;
}

.highcharts-map-navigation {
	font-size: 1.3em;
	font-weight: bold;
	text-align: center;
}

.highcharts-coloraxis {
	stroke-width: 0;
}

.highcharts-coloraxis-marker {
	fill: $light;
}

.highcharts-null-point {
	fill: #f7f7f7;
}

/* 3d charts */
.highcharts-3d-frame {
	fill: transparent;
}

/* Exporting module */
.highcharts-contextbutton {
	fill: transparent;
	/* needed to capture hover */
	stroke: transparent;
	stroke-linecap: round;
}

.highcharts-contextbutton:hover {
	fill: transparent;
	stroke: transparent;
	cursor: pointer;
}

.highcharts-button-symbol {
	stroke: $light;
	stroke-width: 2px;
	&:hover {
		stroke: $snow;
	}
}

.highcharts-menu {
	border: 1px solid $dark-600;
	border-radius: 0.25rem;
	background: $dark-300;
	padding: 5px 0;
	box-shadow: 3px 3px 10px #000;
}

.highcharts-menu-item {
	padding: 0.5em 1em;
	background: none;
	color: $light;
	cursor: pointer;
	transition:
		background 250ms,
		color 250ms;
}

.highcharts-menu-item:hover {
	background: $dark-600;
	color: #ffffff;
}

/* Drilldown module */
.highcharts-drilldown-point {
	cursor: pointer;
}

.highcharts-drilldown-data-label text,
text.highcharts-drilldown-data-label,
.highcharts-drilldown-axis-label {
	cursor: pointer;
	fill: $light;
	font-weight: normal;
	text-decoration: none;
}

/* No-data module */
.highcharts-no-data text {
	font-weight: bold;
	font-size: 12px;
	fill: $light;
}

/* Drag-panes module */
.highcharts-axis-resizer {
	cursor: ns-resize;
	stroke: black;
	stroke-width: 2px;
}

/* Bullet type series */
.highcharts-bullet-target {
	stroke-width: 0;
}

/* Lineargauge type series */
.highcharts-lineargauge-target {
	stroke-width: 1px;
	stroke: $dark-300;
}

.highcharts-lineargauge-target-line {
	stroke-width: 1px;
	stroke: $dark-300;
}

/* Annotations module */
.highcharts-annotation-label-box {
	stroke-width: 1px;
	stroke: #000000;
	fill: #000000;
	fill-opacity: 0.75;
}

.highcharts-annotation-label text {
	fill: #e6e6e6;
}

/* Gantt */
.highcharts-treegrid-node-collapsed,
.highcharts-treegrid-node-expanded {
	cursor: pointer;
}

.highcharts-point-connecting-path {
	fill: none;
}

.highcharts-grid-axis .highcharts-tick {
	stroke-width: 0px;
}

.highcharts-grid-axis .highcharts-axis-line {
	stroke-width: 0px;
}

.highcharts-point.zone-0 {
	fill: dodgerblue;
}
.highcharts-point.zone-1 {
	fill: #3396ff;
}
.highcharts-point.zone-2 {
	fill: #66b0ff;
}

.highcharts-drillup-button {
	fill: $primary;
	stroke: $primary;
}
.highcharts-drillup-button text {
	fill: white;
	stroke: white;
}

/* Series options */
/* Default colors */

@each $color, $value in $highcharts-colors {
	.highcharts-series-#{$color} {
		fill: $value;
		stroke: $value;
	}

	.highcharts-color-#{$color} {
		fill: $value !important;
		stroke: $value !important;
	}
	.highcharts-color-#{$color} {
		&.highcharts-pie-series {
			fill-opacity: 0.65 !important;
			fill: $value !important;
			stroke: $value !important;
		}
	}

	/* Define the stop colors */
	#gradient-#{$color} stop {
		stop-color: $value;
	}
	#gradient-#{$color} stop[offset="0"] {
		stop-opacity: 0.75;
	}
	#gradient-#{$color} stop[offset="1"] {
		stop-opacity: 0;
	}
	.highcharts-color-#{$color} .highcharts-area {
		fill-opacity: 1 !important;
		fill: url(#gradient-#{$color});
	}
	// .highcharts-color-#{$color} .highcharts-area {
	//   fill-opacity: 1 !important;
	//   fill: url("data:image/svg+xml;base64,PHN2ZyBzdHlsZT0iaGVpZ2h0OiAwIj4NCgkJPGRlZnM+DQoJCQk8bGluZWFyR3JhZGllbnQgaWQ9ImdyYWRpZW50LTAiIHgxPSIwIiB5MT0iMCIgeDI9IjAiIHkyPSIxIj4NCgkJCQk8c3RvcCBvZmZzZXQ9IjAiIC8+DQoJCQkJPHN0b3Agb2Zmc2V0PSIxIiAvPg0KCQkJPC9saW5lYXJHcmFkaWVudD4NCgkJCTxsaW5lYXJHcmFkaWVudCBpZD0iZ3JhZGllbnQtMSIgeDE9IjAiIHkxPSIwIiB4Mj0iMCIgeTI9IjEiPg0KCQkJCTxzdG9wIG9mZnNldD0iMCIgLz4NCgkJCQk8c3RvcCBvZmZzZXQ9IjEiIC8+DQoJCQk8L2xpbmVhckdyYWRpZW50Pg0KCQkJPGxpbmVhckdyYWRpZW50IGlkPSJncmFkaWVudC0yIiB4MT0iMCIgeTE9IjAiIHgyPSIwIiB5Mj0iMSI+DQoJCQkJPHN0b3Agb2Zmc2V0PSIwIiAvPg0KCQkJCTxzdG9wIG9mZnNldD0iMSIgLz4NCgkJCTwvbGluZWFyR3JhZGllbnQ+DQoJCQk8bGluZWFyR3JhZGllbnQgaWQ9ImdyYWRpZW50LTMiIHgxPSIwIiB5MT0iMCIgeDI9IjAiIHkyPSIxIj4NCgkJCQk8c3RvcCBvZmZzZXQ9IjAiIC8+DQoJCQkJPHN0b3Agb2Zmc2V0PSIxIiAvPg0KCQkJPC9saW5lYXJHcmFkaWVudD4NCgkJCTxsaW5lYXJHcmFkaWVudCBpZD0iZ3JhZGllbnQtNCIgeDE9IjAiIHkxPSIwIiB4Mj0iMCIgeTI9IjEiPg0KCQkJCTxzdG9wIG9mZnNldD0iMCIgLz4NCgkJCQk8c3RvcCBvZmZzZXQ9IjEiIC8+DQoJCQk8L2xpbmVhckdyYWRpZW50Pg0KCQkJPGxpbmVhckdyYWRpZW50IGlkPSJncmFkaWVudC01IiB4MT0iMCIgeTE9IjAiIHgyPSIwIiB5Mj0iMSI+DQoJCQkJPHN0b3Agb2Zmc2V0PSIwIiAvPg0KCQkJCTxzdG9wIG9mZnNldD0iMSIgLz4NCgkJCTwvbGluZWFyR3JhZGllbnQ+DQoJCQk8bGluZWFyR3JhZGllbnQgaWQ9ImdyYWRpZW50LTYiIHgxPSIwIiB5MT0iMCIgeDI9IjAiIHkyPSIxIj4NCgkJCQk8c3RvcCBvZmZzZXQ9IjAiIC8+DQoJCQkJPHN0b3Agb2Zmc2V0PSIxIiAvPg0KCQkJPC9saW5lYXJHcmFkaWVudD4NCgkJCTxsaW5lYXJHcmFkaWVudCBpZD0iZ3JhZGllbnQtNyIgeDE9IjAiIHkxPSIwIiB4Mj0iMCIgeTI9IjEiPg0KCQkJCTxzdG9wIG9mZnNldD0iMCIgLz4NCgkJCQk8c3RvcCBvZmZzZXQ9IjEiIC8+DQoJCQk8L2xpbmVhckdyYWRpZW50Pg0KCQkJPGxpbmVhckdyYWRpZW50IGlkPSJncmFkaWVudC04IiB4MT0iMCIgeTE9IjAiIHgyPSIwIiB5Mj0iMSI+DQoJCQkJPHN0b3Agb2Zmc2V0PSIwIiAvPg0KCQkJCTxzdG9wIG9mZnNldD0iMSIgLz4NCgkJCTwvbGluZWFyR3JhZGllbnQ+DQoJCQk8bGluZWFyR3JhZGllbnQgaWQ9ImdyYWRpZW50LTkiIHgxPSIwIiB5MT0iMCIgeDI9IjAiIHkyPSIxIj4NCgkJCQk8c3RvcCBvZmZzZXQ9IjAiIC8+DQoJCQkJPHN0b3Agb2Zmc2V0PSIxIiAvPg0KCQkJPC9saW5lYXJHcmFkaWVudD4NCgkJPC9kZWZzPg0KCTwvc3ZnPg==") !important;
	// }

	.highcharts-color-#{$color}-dashed {
		fill: $value;
		stroke: $value;
		stroke-dasharray: 10, 12 !important;
	}
}

.highcharts-negative {
	fill: $danger;
	stroke: $danger;
}

.highcharts-area {
	fill-opacity: 0.75;
	stroke-width: 0;
}

.article {
	.highcharts-background {
		background-color: transparent !important;
	}
}
