.rdt_TableHeader{
    padding-left: 0rem !important;
    padding-right: 0rem !important;
}
.rdt_Table {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
}
.rdt_TableCol {
   font-weight: bolder !important;
}