@import "quill_snow";
@import "quill_bubble";
@import "quill_core";
@import "quill_theme";

#editor-container3 {
	font-size: $h2-font-size;
	color: $gray-500;
	background-color: none;
}

#editor-container3 h1 {
	font-size: $h2-font-size;
	color: $gray-500;
	background-color: none;
	margin: 0em;
	padding: 0em;
}
#editor-container3 p {
	font-size: $h2-font-size;
	color: $gray-500;
	margin: 0em;
	background-color: none;
	padding: 0em;
}

#editor-container2 {
	font-size: $h4-font-size;
	color: $light;
	background-color: none;
	font-weight: 300;
}

#editor-container2 h2 {
	font-size: $h4-font-size;
	font-weight: 300;
	background-color: none;
	color: $light;
}
#editor-container2 p {
	font-size: $h4-font-size;
	font-weight: 300;
	background-color: none;
	color: $light;
}

#editor-container {
	font-size: ($font-size-base * 1.1);
	background-color: none;
	padding-bottom: 1em;
}
#editor-container h2 {
	font-size: $h4-font-size;
	background-color: none;
	font-weight: 300;
	color: $light;
}
#editor-container p {
	font-size: ($font-size-base * 1.1);
	background-color: none;
	padding-bottom: 1em;
}
#editor-container ul li {
	font-size: ($font-size-base * 1.1);
	background-color: none;
	padding-bottom: 1em;
}

//ARTICLE

#article-title {
	font-size: ($h1-font-size * 1.2);
	text-transform: capitalize;
	color: $white !important;
	margin: 0em;
	padding: 0em;
	font-family: "Cormorant Garamond", serif;
}
#article-title > span {
	font-size: ($h1-font-size * 1.2);
	text-transform: capitalize;
	color: $white !important;
	margin: 0em;
	padding: 0em;
	font-family: "Cormorant Garamond", serif;
}
#article-title > div.ql-editor > p > span {
	font-size: ($h1-font-size * 1.2);
	text-transform: capitalize;
	color: $white !important;
	margin: 0em;
	padding: 0em;
	font-family: "Cormorant Garamond", serif;
}

#article-title h1 {
	font-size: ($h1-font-size * 1.2);
	text-transform: capitalize;
	color: $white !important;
	margin: 0em;
	padding: 0em;
	font-family: "Cormorant Garamond", serif;
}
#article-title p {
	font-size: ($h1-font-size * 1.2);
	text-transform: capitalize;
	color: $white;
	margin: 0em;
	padding: 0em;
	font-family: "Cormorant Garamond", serif;
}

//subtitle
#article-subtitle {
	font-size: $h4-font-size;
	font-weight: 300;
	color: $light;
	margin: 0em;
	padding: 0em;
}

#article-subtitle h1 {
	font-size: $h4-font-size;
	color: $light;
	font-weight: 300;
	margin: 0em;
	padding: 0em;
}
#article-subtitle p {
	font-size: $h4-font-size;
	color: $light;
	font-weight: 300;
	margin: 0em;
	padding: 0em;
}

//body
#article-body {
	font-size: ($font-size-base * 1.5);
	margin: 0em;
	padding-left: 0em;
	padding-right: 0em;
	padding-top: 0em;
	padding-bottom: 1em;
	font-family: "Cormorant Garamond", serif;
}

#article-body p {
	font-size: ($font-size-base * 1.5);
	margin: 0em;
	padding-left: 0em;
	padding-right: 0em;
	padding-top: 0em;
	padding-bottom: 1em;
	font-family: "Cormorant Garamond", serif;
}
#article-body ul {
	font-size: ($font-size-base * 1.5);
	margin: 0em;
	padding-left: 0em;
	padding-right: 0em;
	padding-top: 1em;
	padding-bottom: 1em;
	font-family: "Cormorant Garamond", serif;
}
#article-body ul li {
	font-size: ($font-size-base * 1.5);
	margin: 0em;
	padding-left: 0em;
	padding-right: 0em;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	font-family: "Cormorant Garamond", serif;
}

#swot-strength {
	padding: 0em;
	margin: 0em;
}
#swot-strength h3 {
	padding: 0em;
	margin: 0em;
	font-size: $h4-font-size;
	font-weight: 300;
	color: $success;
}

#strength {
	border: 0.1em solid var(--success);
	border-top: none;
	min-height: 6rem;
	margin-bottom: 1em;
	line-height: 1.6em;
}
#strength h3 {
	padding-bottom: 0.3em;
	padding-top: 0.5em;
	font-size: $h4-font-size;
	font-weight: 300;
	color: $success;
}
#strength p {
	padding-bottom: 0.3em;
	padding-top: 0.5em;
	margin: 0em;
}

#strength-toolbar {
	border: 0.1em solid var(--success);
	border-bottom: none;
}

#swot-weakness {
	padding: 0em;
	margin: 0em;
}
#swot-weakness h3 {
	padding: 0em;
	margin: 0em;
	font-size: $h4-font-size;
	font-weight: 300;
	color: $danger;
}

#weakness {
	margin-bottom: 1em;
	border: 0.1em solid var(--danger);
	min-height: 6rem;
	border-top: none;
	line-height: 1.6em;
}
#weakness h3 {
	padding-bottom: 0.3em;
	padding-top: 0.5em;
	font-size: $h4-font-size;
	font-weight: 300;
	color: $danger;
}
#weakness p {
	padding-bottom: 0.3em;
	padding-top: 0.5em;
	margin: 0em;
}

#weakness-toolbar {
	border: 0.1em solid var(--danger);
	border-bottom: none;
}

#swot-opportunity {
	padding: 0em;
	margin: 0em;
}
#swot-opportunity h3 {
	padding: 0em;
	margin: 0em;
	font-size: $h4-font-size;
	font-weight: 300;
	color: $info;
}

#opportunity {
	margin-bottom: 1em;
	border: 0.1em solid var(--info);
	min-height: 6rem;
	border-top: none;
	line-height: 1.6em;
}

#opportunity h3 {
	padding-bottom: 0.3em;
	padding-top: 0.5em;
	font-size: $h4-font-size;
	font-weight: 300;
	color: $info;
}
#opportunity p {
	padding-bottom: 0.3em;
	padding-top: 0.5em;
	margin: 0em;
}
#opportunity-toolbar {
	border: 0.1em solid var(--info);
	border-bottom: none;
}

#swot-threat {
	padding: 0em;
	margin: 0em;
}
#swot-threat h3 {
	padding: 0em;
	margin: 0em;
	font-size: $h4-font-size;
	font-weight: 300;
	color: $warning;
}

#threat {
	margin-bottom: 1em;
	border: 0.1em solid var(--warning);
	min-height: 6rem;
	border-top: none;
	line-height: 1.6em;
}
#threat h3 {
	padding-bottom: 0.3em;
	padding-top: 0.5em;
	font-size: $h4-font-size;
	font-weight: 300;
	color: $warning;
}
#threat p {
	padding-bottom: 0.3em;
	padding-top: 0.5em;
	margin: 0em;
}
#threat-toolbar {
	border: 0.1em solid var(--warning);
	border-bottom: none;
}

.article-tag {
	text-transform: uppercase;
}

.article-title {
	font-size: ($h1-font-size);
	text-transform: capitalize;
	color: $white;
	margin: 0em;
	padding: 0em;
	font-family: "Cormorant Garamond", serif;
}

.article-subtitle {
	font-size: $h4-font-size;
	font-weight: 300;
	color: $light;
	margin: 0em;
	padding: 0em;
}

#overview > .ql-editor > h1 {
	padding-bottom: 0.3em;
	padding-top: 0.5em;
	font-size: $h3-font-size;
	font-weight: 300;
	color: $light;
}

#overview > .ql-editor > h3 {
	padding-bottom: 0.3em;
	padding-top: 0.5em;
	font-size: $h4-font-size;
	font-weight: $font-weight-bold;
	color: $light;
}

#overview > .ql-editor > p {
	padding-bottom: 0.3em;
	padding-top: 0.5em;
}
