@keyframes slide-in {
	0% {
		bottom: -100vh;
		opacity: 0;
	}

	100% {
		bottom: 0;
		z-index: 200;
		opacity: 1;
	}
}

@keyframes slide-out {
	0% {
		bottom: 0;
		z-index: 200;
		opacity: 1;
	}

	100% {
		bottom: -100vh;
		opacity: 0;
	}
}

.slide-container {
	position: fixed;
	top: $topbar-height;
	right: 0;

	@media (min-width: 0) and (max-width: #{$md}) {
		left: 0;
		width: 100vw;
	}

	@media (min-width: #{$md}) and (max-width: #{$xxl}) {
		left: $leftbar-width-collapsed;
		width: calc(100vw - #{$leftbar-width-collapsed});
	}

	@media (min-width: #{$xxl}) {
		left: $leftbar-width;
		width: calc(100vw - #{$leftbar-width});
	}

	// @include media-breakpoint-down(lg) {
	// }

	.slide {
		&.slide-in {
			position: relative;
			animation-name: slide-in;
			animation-duration: 0.5s;
			animation-iteration-count: 1;
			animation-direction: normal;
			min-width: calc(100% - #{$leftbar-width});
			// min-height: 100vh;
			overflow-y: scroll;
			height: calc(100vh - #{$topbar-height});
		}

		&.slide-out {
			position: relative;
			animation-name: slide-out;
			animation-duration: 0.5s;
			animation-iteration-count: 1;
			animation-direction: normal;
			min-width: calc(100% - #{$leftbar-width});
			height: calc(100vh - #{$topbar-height});
		}
	}
}

@keyframes zoomIn {
	from {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}

	50% {
		opacity: 1;
	}
}

@keyframes zoomIn-sm {
	from {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}

	50% {
		opacity: 1;
	}
}

@keyframes zoomIn-md {
	from {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}

	50% {
		opacity: 1;
	}
}

@keyframes zoomIn-lg {
	from {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}

	50% {
		opacity: 1;
	}
}

@keyframes zoomIn-xl {
	from {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}

	50% {
		opacity: 1;
	}
}

.zoomIn {
	animation-duration: 1.8s;

	@include media-breakpoint-only(xs) {
		animation-name: zoomIn;
	}

	@include media-breakpoint-only(sm) {
		animation-name: zoomIn-sm;
	}

	@include media-breakpoint-only(md) {
		animation-name: zoomIn-md;
	}

	@include media-breakpoint-only(lg) {
		animation-name: zoomIn-lg;
	}

	@include media-breakpoint-only(xl) {
		animation-name: zoomIn-xl;
	}
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
	}

	50% {
		-webkit-transform: scale3d(1.05, 1.05, 1.05);
		transform: scale3d(1.05, 1.05, 1.05);
	}

	to {
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
	}
}

@keyframes pulse {
	0% {
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
	}

	50% {
		-webkit-transform: scale3d(1.05, 1.05, 1.05);
		transform: scale3d(1.05, 1.05, 1.05);
	}

	to {
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
	}
}

.pulse-hover {
	&:hover {
		-webkit-animation-name: pulse;
		animation-name: pulse;
	}
}

/* ----------------------------------------------
 * Generated by Animista on 2021-10-27 20:51:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
	0% {
		-webkit-transform: translateY(1000px);
		transform: translateY(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes slide-in-bottom {
	0% {
		-webkit-transform: translateY(1000px);
		transform: translateY(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

.slide-in-bottom {
	-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
	animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}
