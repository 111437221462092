$shadow-values: ("25" 0.25, "50" 0.5, "75" 0.75, "100" 1);
$shadow-positions: "top", "bottom", "left", "right";

@each $shadow-name, $shadow-value in $shadow-values {
  .shadow-#{$shadow-name} {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, $shadow-value) !important;
  }
}

@each $color, $value in $theme-colors {
  .shadow-sm-#{$color} {
    box-shadow: 0 0 35px 0 rgba($value, 0.5);
  }
}

@each $color, $value in $theme-colors {
  .shadow-lg-#{$color} {
    box-shadow: 0 0 45px 0 rgba($value, 0.12);
  }
}

@each $color, $value in $theme-colors {
  .shadow-#{$color} {
    box-shadow: 0 0.5rem 1rem 0 rgba($value, 0.12);
  }
}

.shadow-alt,
.shadow-1 {
  box-shadow: $shadow-alt;
}

.card-shadow {
  // card-shadow
  box-shadow: $card-box-shadow;
}
