//Config
@import "../../node_modules/bootstrap/scss/functions";
@import "custom/components/bs5/functions";

@import "../../node_modules/bootstrap/scss/variables";
@import "config/colors";
@import "config/variables";
@import "config/customvariables";
@import "vendor/materialUI";
@import "custom/components/bs5/bootstrap";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "custom/components/root";

// Structure
@import "custom/structure/general";
@import "custom/structure/left-sidebar";
@import "custom/structure/topbar";
@import "custom/structure/right-sidebar";
@import "custom/structure/page-head";
@import "custom/structure/footer";

// horizontal nav
@import "custom/structure/horizontal-nav";

//Fonts
@import "custom/fonts/Roboto";
@import "custom/fonts/Inter";
@import "custom/fonts/Orbitron";
@import "custom/fonts/IBM_Plex_Mono";
@import "custom/fonts/Escrow";

//Components
@import "custom/components/mixins";
@import "custom/components/accordions";
@import "custom/components/avatar";
@import "custom/components/breadcrumb";
@import "custom/components/buttons";
@import "custom/components/badge";
@import "custom/components/card";
@import "custom/components/custom-forms";
@import "custom/components/dropdown";
@import "custom/components/forms";
@import "custom/components/modal";
@import "custom/components/nav";
@import "custom/components/offcanvas";
@import "custom/components/pagination";
@import "custom/components/particle";
@import "custom/components/popover";
@import "custom/components/print";
@import "custom/components/progress";
@import "custom/components/reboot";
@import "custom/components/ribbons";
@import "custom/components/switch";
@import "custom/components/tables";
@import "custom/components/type";
@import "custom/components/toasts";
@import "custom/components/utilities";
@import "custom/components/widgets";
@import "custom/components/social";
@import "custom/components/steps";
@import "custom/components/preloader";
@import "custom/components/hero";
@import "custom/components/dealmodal";
@import "custom/components/customselect";
@import "custom/components/marketssearch";
@import "custom/components/timeline";
@import "custom/components/combobox";
@import "custom/components/circularprogressbar";
@import "custom/components/carousel";
@import "custom/components/containers";
@import "custom/components/images";

// Pages
@import "custom/pages/authentication";
@import "custom/pages/admin-companies";
@import "custom/pages/deal-feed";
@import "custom/pages/swaps";

// Plugins
@import "custom/plugins/datatables";

//Vendors
@import "vendor/datepicker";
@import "vendor/daterangepicker";
@import "vendor/bootstrap-datepicker.min";
@import "vendor/jquery.toast.min";
@import "vendor/select2.min";
@import "vendor/jquery.toast.min";
@import "vendor/bootstrap-timepicker.min";
@import "vendor/jquery.bootstrap-touchspin.min";
@import "vendor/highcharts/highcharts";

// Plugins
@import "custom/plugins/metis-menu";
@import "custom/plugins/datatable";
@import "custom/plugins/daterange";
@import "custom/plugins/datepicker";
@import "custom/plugins/dragula";
@import "custom/plugins/dropzone";
@import "custom/plugins/form-wizard";
@import "custom/plugins/select2";
@import "custom/plugins/simplebar";
@import "custom/plugins/toaster";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/bootstrap-timepicker";
@import "custom/plugins/typehead";
@import "custom/plugins/sparklines";
@import "custom/plugins/rateit";
@import "custom/plugins/ion-rangeslider";
@import "custom/plugins/quilljs";
@import "custom/plugins/quillmentions";
@import "custom/plugins/tippy";
@import "custom/plugins/emojimart";
@import "custom/plugins/datepicker-react";
@import "custom/plugins/react-datatable-component";
@import "custom/plugins/react-select";
@import "custom/plugins/aos";
@import "custom/plugins/flickity";

// Animations
@import "../../node_modules/animate.css/animate.css";
