.particle-network-animation {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 100vh;

	&::before {
		z-index: -2;
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}
.glow {
	z-index: -1;
	position: fixed;
	top: 50%;
	left: 50%;
	background-image: radial-gradient(circle closest-side, rgba(255, 255, 255, 0.025), transparent);
}
$duration: 25s;
.glow-1 {
	width: 150vw;
	height: 150vh;
	margin-top: -75vh;
	margin-left: -75vw;
	animation: glow-1-move $duration linear infinite both;
}
@keyframes glow-1-move {
	from {
		transform: translate(-100%, 100%);
	}
	to {
		transform: translate(100%, -100%);
	}
}
.glow-2 {
	width: 100vw;
	height: 100vh;
	margin-top: -50vh;
	margin-left: -50vw;
	animation: glow-2-move $duration linear calc($duration / 3) infinite both;
}
@keyframes glow-2-move {
	from {
		transform: translate(-100%, 0%);
	}
	to {
		transform: translate(100%, 100%);
	}
}
.glow-3 {
	width: 120vw;
	height: 120vh;
	margin-top: -60vh;
	margin-left: -60vw;
	animation: glow-3-move $duration linear calc($duration / 3) * 2 infinite both;
}
@keyframes glow-3-move {
	from {
		transform: translate(100%, 100%);
	}
	to {
		transform: translate(0%, -100%);
	}
}
