// Background lighten

@mixin bg-variant-light($color) {
	background-color: rgba($color, 0.25) !important;
}

@mixin bg-variant-dark($color) {
	background-color: darken($color, 0.25) !important;
}

@mixin bg-opacity($color, $opacity) {
	background-color: rgba($color, $opacity) !important;
}
