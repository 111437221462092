// 
// authentication.scss
//

// authentication pages background
body.authentication-bg {
    // background-image: $auth-bg-pattern-img;
    min-height: fit-content !important;
    background-size: cover;
    background-position: center;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: $secondary transparent;
    scrollbar-width: none;
    // background-image:$auth-bg-nodes;
    // background-size: 5%;
    // background-position: center;
    // background-repeat: repeat;
}    


.authentication-bg.enlarged,
.auth-fluid-pages.enlarged,
body.auth-fluid-pages[data-leftbar-compact-mode=condensed] {
    min-height: 100px;
}

// Logout page
.logout-icon {
    width: 140px;
}

// Authentication fluid 
.auth-fluid {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 100vh;
    flex-direction: row;
    align-items: stretch;
    // background-image: url("https://saxoncircle.blob.core.windows.net/login-page/pattern.svg") center;
    // background-size: cover;
    background-image:$auth-bg-nodes;
    background-size: 5%;
    background-position: center;
    background-repeat: repeat;

    .auth-fluid-form-box {
        max-width: 480px;
        border-radius: 0;
        z-index: 2;
        padding: 3rem 2rem;
        background-color: $auth-bg;
        position: relative;
        width: 100%;
    }
    .auth-fluid-form-box-wide {
        max-width: 576px;
        border-radius: 0;
        z-index: 2;
        padding: 3rem 2rem;
        background-color: $auth-bg;
        position: relative;
        width: 100%;
    }

    .auth-fluid-right {
        padding: 6rem 3rem;
        flex: 1;
        position: relative;
        color: $white;
        background-color: rgba($light,.3)
    }
}

.auth-brand {
    margin-bottom: 2rem;

    .logo-dark  {
        display: $logo-auth-dark-display;
    }

    .logo-light  {
        display: $logo-auth-light-display;
    }
}

.auth-user-testimonial {
    position: absolute;
    margin: 0 auto;
    padding: 0 1.75rem;
    bottom: 3rem;
    left: 0;
    right: 0;

    p.lead {
        font-size: 1.125rem;
        margin: 0 auto 20px auto;
        max-width: 700px;
    }
}

@include media-breakpoint-up(lg) {
    .auth-brand {
        position: absolute;
        top: 3rem;
    }
}

@include media-breakpoint-down(md) {
    .auth-fluid {
        display: block;

        .auth-fluid-form-box {
            max-width: 100%;
            min-height: 100vh
        }
        .auth-fluid-form-box-wide {
            max-width: 100%;
            min-height: 100vh
        }
    
        .auth-fluid-right {
            display: none;
        }
    }
}