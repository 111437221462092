/* Plot bands and polar pane backgrounds */
.highcharts-plot-band,
.highcharts-pane {
  fill: #000000;
  fill-opacity: 0.05;
}

.highcharts-plot-line {
  fill: none;
  stroke: $sg-400;
  stroke-width: 1px;
  stroke-dasharray: 1rem;
}

.highcharts-plot-line-label {
  fill:$sg-400 !important;
  font-weight:400
}