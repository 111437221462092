@use "sass:math";

//
// _badges.scss
//
.badge {
	padding-top: math.div($badge-padding-x + $badge-padding-y, 2) em;
}

// Lighten badge (soft)

@each $color, $value in $theme-colors {
	.badge-#{$color}-lighten {
		@include badge-variant-light($value);
	}
}

// Outline badge

@each $color, $value in $theme-colors {
	.badge-outline-#{$color} {
		@include badge-variant-outline($value);
	}
}
