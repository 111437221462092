$border-values: ("0" 0px, "1" 1px, "2" 2px, "3" 3px, "4" 4px, "5" 5px);
$border-positions: "top", "bottom", "left", "right";
$border-lighten-values: ("1" 2%, "2" 4%, "3" 6%, "4" 8%, "5" 10%);
$border-radius: ("0" 0rem, "1" 0.3rem, "2" 0.4rem, "3" 0.5rem, "4" 0.6rem, "5" 0.7rem, "6" 0.8rem, "7" 0.8rem, "8" 0.9rem, "9" 1rem, "10" 1.2rem, "50" 50%);

@each $border-name, $border-value in $border-values {
	@each $color, $value in $theme-colors {
		.border-#{$border-name}-#{$color} {
			border: $border-value solid $value;
		}
		//Position Borders
		@each $position-name in $border-positions {
			.border-#{$border-name}-#{$color}-#{$position-name} {
				border-#{$position-name}: $border-value solid $value !important;
			}
		}
	}
}

@each $border-name, $border-value in $border-values {
	@each $color, $value in $theme-colors {
		//ACTIVE
		.border-#{$border-name}-#{$color}-active {
			&.active {
				border: $border-value solid $value !important;
			}
		}
		@each $position-name in $border-positions {
			.border-#{$border-name}-#{$color}-#{$position-name}-active {
				&.active {
					border-#{$position-name}: $border-value solid $value !important;
				}
			}
		}
		//FOCUS
		.border-#{$border-name}-#{$color}-focus {
			&:focus {
				border: $border-value solid $value !important;
			}
		}
		@each $position-name in $border-positions {
			.border-#{$border-name}-#{$color}-#{$position-name}-focus {
				&:focus {
					border-#{$position-name}: $border-value solid $value !important;
				}
			}
		}
		//FOCUS WITHIN
		.border-#{$border-name}-#{$color}-focus-within {
			&:focus-within {
				border: $border-value solid $value !important;
			}
		}
		@each $position-name in $border-positions {
			.border-#{$border-name}-#{$color}-#{$position-name}-focus-within {
				&:focus-within {
					border-#{$position-name}: $border-value solid $value !important;
				}
			}
		}

		//HOVERING
		// EG: .border-1-primary-hover
		.border-#{$border-name}-#{$color}-hover {
			&:hover {
				border: $border-value solid $value !important;
			}
		}
		// EG: .border-1-primary-bottom-hover
		@each $position-name in $border-positions {
			.border-#{$border-name}-#{$color}-#{$position-name}-hover {
				&:hover {
					border-#{$position-name}: $border-value solid $value !important;
				}
			}
		}
	}
	//TRANSPARENT

	.border-#{$border-name}-transparent {
		border: $border-value solid transparent !important;
	}

	@each $position-name in $border-positions {
		.border-#{$border-name}-transparent-#{$position-name} {
			border-#{$position-name}: $border-value solid transparent;
		}
	}
}

//light borders
@each $border-name, $border-value in $border-values {
	@each $color, $value in $theme-colors {
		.border-#{$border-name}-#{$color}-lighten {
			border-style: solid;
			border-color: lighten($value, 2%) !important;
			border-width: $border-value !important;
		}
	}
}

//POSITION BORDERS
@each $border-name, $border-value in $border-values {
	@each $color, $value in $theme-colors {
		.border-x-#{$border-name}-#{$color} {
			border-left-style: solid;
			border-right-style: solid;
			border-left-color: $value;
			border-right-color: $value;
			border-left-width: $border-value;
			border-right-width: $border-value;
		}
		.border-y-#{$border-name}-#{$color} {
			border-top-style: solid;
			border-bottom-style: solid;
			border-top-color: $value;
			border-bottom-color: $value;
			border-top-width: $border-value;
			border-bottom-width: $border-value;
		}
	}
	.border-x-#{$border-name} {
		border-right-style: solid;
		border-left-style: solid;
		border-right-width: $border-value !important;
		border-left-width: $border-value !important;
	}
	.border-y-#{$border-name} {
		border-top-style: solid;
		border-bottom-style: solid;
		border-top-width: $border-value !important;
		border-bottom-width: $border-value !important;
	}
}
.border-x-0 {
	border-left-width: 0 !important;
	border-right-width: 0 !important;
}
.border-y-0 {
	border-top-width: 0 !important;
	border-bottom-width: 0 !important;
}

@each $border-name, $border-value in $border-values {
	@each $color, $value in $theme-colors {
		@each $percent, $lighten-percent in $border-lighten-values {
			.border-#{$border-name}-#{$color}-lighten-#{$percent} {
				border-style: solid;
				border-color: lighten($value, $lighten-percent) !important;
				border-width: $border-value !important;
			}
		}
	}
}

@each $radius, $value in $border-radius {
	.border-radius-#{$radius} {
		border-radius: $value !important;
	}
}

@each $radius, $value in $border-radius {
	.border-radius-top-#{$radius} {
		border-top-right-radius: $value !important;
		border-top-left-radius: $value !important;
	}
}
@each $radius, $value in $border-radius {
	.border-radius-bottom-#{$radius} {
		border-bottom-right-radius: $value !important;
		border-bottom-left-radius: $value !important;
	}
}

@each $radius, $value in $border-radius {
	.border-radius-left-#{$radius} {
		border-top-left-radius: $value !important;
		border-bottom-left-radius: $value !important;
	}
	.border-radius-right-#{$radius} {
		border-top-right-radius: $value !important;
		border-bottom-right-radius: $value !important;
	}
}

@each $color, $value in $theme-colors {
	.form-control-focus-#{$color}:focus {
		border-color: $value !important;
	}
}

@each $color, $value in $theme-colors {
	.hover-#{$color}:hover {
		background-color: rgba($value, 0.1) !important;
		border-style: solid !important;
		border-color: $value !important;
		border-width: 0.03rem;
	}
}

@each $breakpoint, $value in $grid-breakpoints {
	@include media-breakpoint-only($breakpoint) {
		.border-#{$breakpoint}-none {
			border-width: 0 !important;
		}
	}
	@include media-breakpoint-only($breakpoint) {
		.border-x-#{$breakpoint}-none {
			border-left-width: 0 !important;
			border-right-width: 0 !important;
		}
	}
	@include media-breakpoint-only($breakpoint) {
		.border-y-#{$breakpoint}-none {
			border-top-width: 0 !important;
			border-bottom-width: 0 !important;
		}
	}
}

.border-dashed {
	border-style: dashed !important;
}
