//
// lift.scss
// Theme utility
//

.lift {
  transition: box-shadow 0.25s ease, transform 0.25s ease-in-out;
  &:hover,
  :focus {
    box-shadow: $box-shadow-lift;
    transform: translate3d(0, -4px, 0);
  }
  &.lift-sm {
    transition: box-shadow 0.25s ease, transform 0.25s ease-in-out;
    &:hover,
    :focus {
      box-shadow: $box-shadow-lift-lg;
      transform: translate3d(0, -3px, 0);
    }
  }
  &.lift-lg {
    transition: box-shadow 0.25s ease, transform 0.25s ease-in-out;
    &:hover,
    :focus {
      box-shadow: $box-shadow-lift-lg;
      transform: translate3d(0, -8px, 0);
    }
  }
}
