.datepicker-dropdown,
.dropdown-menu {
	background-color: $dropdown-bg;
	color: $sg-500;
}

.cancelBtn {
	background-color: transparent;
	color: $white;
}

.applyBtn {
	background-color: $primary;
	border-color: $primary;
	color: $white;
}

.applyBtn:hover {
	background-color: darken($color: #007bff, $amount: 2);
	border-color: darken($color: #007bff, $amount: 2);
	color: $white;
}

.daterangepicker {
	position: absolute;
	color: inherit;
	background-color: $sg-100;
	border-radius: 4px;
	border: 1px solid #ddd;
	width: 278px;
	max-width: none;
	padding: 0;
	margin-top: 7px;
	top: 100px;
	left: 20px;
	z-index: 3001;
	display: none;
	font-family: arial;
	font-size: 15px;
	line-height: 1em;
}

.daterangepicker:before,
.daterangepicker:after {
	position: absolute;
	display: inline-block;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	content: "";
}

.daterangepicker:before {
	top: -7px;
	border-right: 7px solid transparent;
	border-left: 7px solid transparent;
	border-bottom: 7px solid #ccc;
}

.daterangepicker:after {
	top: -6px;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #fff;
	border-left: 6px solid transparent;
}

.daterangepicker.opensleft:before {
	right: 9px;
}

.daterangepicker.opensleft:after {
	right: 10px;
}

.daterangepicker.openscenter:before {
	left: 0;
	right: 0;
	width: 0;
	margin-left: auto;
	margin-right: auto;
}

.daterangepicker.openscenter:after {
	left: 0;
	right: 0;
	width: 0;
	margin-left: auto;
	margin-right: auto;
}

.daterangepicker.opensright:before {
	left: 9px;
}

.daterangepicker.opensright:after {
	left: 10px;
}

.daterangepicker.drop-up {
	margin-top: -7px;
}

.daterangepicker.drop-up:before {
	top: initial;
	bottom: -7px;
	border-bottom: initial;
	border-top: 7px solid #ccc;
}

.daterangepicker.drop-up:after {
	top: initial;
	bottom: -6px;
	border-bottom: initial;
	border-top: 6px solid #fff;
}

.daterangepicker.single .daterangepicker .ranges,
.daterangepicker.single .drp-calendar {
	float: none;
}

.daterangepicker.single .drp-selected {
	display: none;
}

.daterangepicker.show-calendar .drp-calendar {
	display: block;
}

.daterangepicker.show-calendar .drp-buttons {
	display: block;
}

.daterangepicker.auto-apply .drp-buttons {
	display: none;
}

.daterangepicker .drp-calendar {
	display: none;
	max-width: 270px;
}

.daterangepicker .drp-calendar.left {
	padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
	padding: 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
	border: none;
}

.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
	color: #fff;
	border: solid $white;
	border-width: 0 2px 2px 0;
	border-radius: 0;
	display: inline-block;
	padding: 3px;
}

.daterangepicker .calendar-table .next span {
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
	white-space: nowrap;
	text-align: center;
	vertical-align: middle;
	min-width: 32px;
	width: 32px;
	height: 24px;
	line-height: 24px;
	font-size: 12px;
	border-radius: 4px;
	border: 1px solid transparent;
	white-space: nowrap;
	cursor: pointer;
}

.daterangepicker .calendar-table {
	border: 1px solid $sg-500;
	border-radius: 4px;
	background-color: $sg-300;
}

.daterangepicker .calendar-table table {
	width: 100%;
	margin: 0;
	border-spacing: 0;
	border-collapse: collapse;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
	background-color: $primary;
	border-color: transparent;
	color: $white;
}

.daterangepicker td.week,
.daterangepicker th.week {
	font-size: 80%;
	color: #ccc;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
	background-color: transparent;
	border-color: transparent;
	color: transparent;
}

.daterangepicker td.in-range {
	background-color: lighten($color: #007bff, $amount: 20);
	border-color: transparent;
	color: $white;
	border-radius: 0;
}

.daterangepicker td.start-date {
	border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
	border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
	border-radius: 4px;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
	background-color: $primary;
	border-color: transparent;
	color: #fff;
}

.daterangepicker th.month {
	width: auto;
}

.daterangepicker td.disabled,
.daterangepicker option.disabled {
	color: #999;
	cursor: not-allowed;
	text-decoration: line-through;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
	font-size: 12px;
	padding: 1px;
	height: auto;
	margin: 0;
	cursor: default;
}

.daterangepicker select.monthselect {
	margin-right: 2%;
	width: 56%;
}

.daterangepicker select.yearselect {
	width: 40%;
}

.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
	width: 50px;
	margin: 0 auto;
	background: #eee;
	border: 1px solid #eee;
	padding: 2px;
	outline: 0;
	font-size: 12px;
}

.daterangepicker .calendar-time {
	text-align: center;
	margin: 4px auto 0 auto;
	line-height: 30px;
	position: relative;
}

.daterangepicker .calendar-time select.disabled {
	color: #ccc;
	cursor: not-allowed;
}

.daterangepicker .drp-buttons {
	clear: both;
	text-align: right;
	padding: 8px;
	border-top: 1px solid #ddd;
	display: none;
	line-height: 12px;
	vertical-align: middle;
}

.daterangepicker .drp-selected {
	display: inline-block;
	font-size: 12px;
	padding-right: 8px;
}

.daterangepicker .drp-buttons .btn {
	margin-left: 8px;
	font-size: 12px;
	font-weight: bold;
	padding: 4px 8px;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
	border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
	border-left: 1px solid #ddd;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
	border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
	border-left: 1px solid #ddd;
}

.daterangepicker .ranges {
	float: none;
	text-align: left;
	margin: 0;
}

.daterangepicker.show-calendar .ranges {
	margin-top: 8px;
}

.daterangepicker .ranges ul {
	list-style: none;
	margin: 0 auto;
	padding: 0;
	width: 100%;
}

.daterangepicker .ranges li {
	font-size: 12px;
	padding: 8px 12px;
	cursor: pointer;
}

.daterangepicker .ranges li:hover {
	background-color: #eee;
}

.daterangepicker .ranges li.active {
	background-color: #08c;
	color: #fff;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
	.daterangepicker {
		width: auto;
	}

	.daterangepicker .ranges ul {
		width: 140px;
	}

	.daterangepicker.single .ranges ul {
		width: 100%;
	}

	.daterangepicker.single .drp-calendar.left {
		clear: none;
	}

	.daterangepicker.single .ranges,
	.daterangepicker.single .drp-calendar {
		float: left;
	}

	.daterangepicker {
		direction: ltr;
		text-align: left;
	}

	.daterangepicker .drp-calendar.left {
		clear: left;
		margin-right: 0;
	}

	.daterangepicker .drp-calendar.left .calendar-table {
		border-right: none;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.daterangepicker .drp-calendar.right {
		margin-left: 0;
	}

	.daterangepicker .drp-calendar.right .calendar-table {
		border-left: none;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.daterangepicker .drp-calendar.left .calendar-table {
		padding-right: 8px;
	}

	.daterangepicker .ranges,
	.daterangepicker .drp-calendar {
		float: left;
	}
}

@media (min-width: 730px) {
	.daterangepicker .ranges {
		width: auto;
	}

	.daterangepicker .ranges {
		float: left;
	}

	.daterangepicker.rtl .ranges {
		float: right;
	}

	.daterangepicker .drp-calendar.left {
		clear: none !important;
	}
}

.datepicker {
	padding: 10px !important;
	td,
	th {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		font-size: 13px;
		line-height: 28px;
	}

	table {
		tr {
			td {
				&.active.active,
				&.active.disabled,
				&.active.disabled.active,
				&.active.disabled.disabled,
				&.active.disabled:active,
				&.active.disabled:hover,
				&.active.disabled:hover.active,
				&.active.disabled:hover.disabled,
				&.active.disabled:hover:active,
				&.active.disabled:hover:hover,
				.active.disabled:hover[disabled],
				.active.disabled[disabled],
				.active:active,
				.active:hover,
				.active:hover.active,
				.active:hover.disabled,
				.active:hover:active,
				.active:hover:hover,
				.active:hover[disabled],
				.active[disabled],
				span.active.active,
				span.active.disabled,
				span.active.disabled.active,
				span.active.disabled.disabled,
				span.active.disabled:active,
				span.active.disabled:hover,
				span.active.disabled:hover.active,
				span.active.disabled:hover.disabled,
				span.active.disabled:hover:active,
				span.active.disabled:hover:hover,
				span.active.disabled:hover[disabled],
				span.active.disabled[disabled],
				span.active:active,
				span.active:hover,
				span.active:hover.active,
				span.active:hover.disabled,
				span.active:hover:active,
				span.active:hover:hover,
				span.active:hover[disabled],
				span.active[disabled],
				&.today,
				&.today.disabled,
				&.today.disabled:hover,
				&.today:hover {
					background-color: $primary !important;
					background-image: none !important;
					color: $white;
				}

				&.day.focused,
				&.day:hover,
				span.focused,
				span:hover {
					background: $white;
				}

				// &.new,
				// &.old,
				// span.new,
				// span.old {
				//     color: $white;
				//     // opacity: 0.4;
				// }
			}
		}
	}

	.datepicker-switch:hover,
	.next:hover,
	.prev:hover,
	tfoot tr th:hover {
		background: $white;
	}
	.datepicker-switch {
		&:hover {
			background-color: $white;
		}
	}
}

.datepicker-dropdown {
	&:after {
		border-bottom: 6px solid $dropdown-bg;
	}
	&:before {
		border-bottom-color: $dropdown-border-color;
	}
	&.datepicker-orient-top {
		&:before {
			border-top: 7px solid $dropdown-border-color;
		}
		&:after {
			border-top: 6px solid $dropdown-bg;
		}
	}
}

/*!
 * Datepicker for Bootstrap v1.9.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */

.datepicker {
	padding: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	direction: ltr;
}
.datepicker-inline {
	width: 220px;
}
.datepicker-rtl {
	direction: rtl;
}
.datepicker-rtl.dropdown-menu {
	left: auto;
}
.datepicker-rtl table tr td span {
	float: right;
}
.datepicker-dropdown {
	top: 0;
	left: 0;
}
.datepicker-dropdown:before {
	content: "";
	display: inline-block;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #999;
	border-top: 0;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	position: absolute;
}
.datepicker-dropdown:after {
	content: "";
	display: inline-block;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #fff;
	border-top: 0;
	position: absolute;
}
.datepicker-dropdown.datepicker-orient-left:before {
	left: 6px;
}
.datepicker-dropdown.datepicker-orient-left:after {
	left: 7px;
}
.datepicker-dropdown.datepicker-orient-right:before {
	right: 6px;
}
.datepicker-dropdown.datepicker-orient-right:after {
	right: 7px;
}
.datepicker-dropdown.datepicker-orient-bottom:before {
	top: -7px;
}
.datepicker-dropdown.datepicker-orient-bottom:after {
	top: -6px;
}
.datepicker-dropdown.datepicker-orient-top:before {
	bottom: -7px;
	border-bottom: 0;
	border-top: 7px solid #999;
}
.datepicker-dropdown.datepicker-orient-top:after {
	bottom: -6px;
	border-bottom: 0;
	border-top: 6px solid #fff;
}
.datepicker table {
	margin: 0;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.datepicker td,
.datepicker th {
	text-align: center;
	width: 20px;
	height: 20px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	border: none;
}
.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
	background-color: $white;
}
.datepicker table tr td.day.focused,
.datepicker table tr td.day:hover {
	background: $sg-400;
	color: $sg-200;
	cursor: pointer;
}
.datepicker table tr td.new,
.datepicker table tr td.old {
	color: $white;
}
.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
	background: 0 0;
	color: #999;
	cursor: default;
}
.datepicker table tr td.highlighted {
	background: #d9edf7;
	border-radius: 0;
}
.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today:hover {
	background-color: #fde19a;
	background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
	background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
	background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
	background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
	background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
	border-color: #fdf59a #fdf59a #fbed50;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	color: #000;
}
.datepicker table tr td.today.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today.disabled:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today[disabled] {
	background-color: #fdf59a;
}
.datepicker table tr td.today.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today:hover:active {
	background-color: #fbf069;
}
.datepicker table tr td.today:hover:hover {
	color: $white;
}
.datepicker table tr td.today.active:hover {
	color: #fff;
}
.datepicker table tr td.range,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover,
.datepicker table tr td.range:hover {
	background: #eee;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}
.datepicker table tr td.range.today,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today:hover {
	background-color: #f3d17a;
	background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
	background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
	background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
	background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
	background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
	border-color: #f3e97a #f3e97a #edde34;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today.disabled:hover[disabled],
.datepicker table tr td.range.today.disabled[disabled],
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today[disabled] {
	background-color: #f3e97a;
}
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today:hover:active {
	background-color: $sg-100;
}
.datepicker table tr td.selected,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected:hover {
	background-color: #9e9e9e;
	background-image: -moz-linear-gradient(to bottom, #b3b3b3, grey);
	background-image: -ms-linear-gradient(to bottom, #b3b3b3, grey);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(grey));
	background-image: -webkit-linear-gradient(to bottom, #b3b3b3, grey);
	background-image: -o-linear-gradient(to bottom, #b3b3b3, grey);
	background-image: linear-gradient(to bottom, #b3b3b3, grey);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
	border-color: grey grey #595959;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	color: #fff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td.selected.active,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled.disabled,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled:hover.disabled,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.disabled:hover:hover,
.datepicker table tr td.selected.disabled:hover[disabled],
.datepicker table tr td.selected.disabled[disabled],
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected:hover.disabled,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected:hover:hover,
.datepicker table tr td.selected:hover[disabled],
.datepicker table tr td.selected[disabled] {
	background-color: grey;
}
.datepicker table tr td.selected.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected:hover:active {
	background-color: $white;
}
.datepicker table tr td.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active:hover {
	background-color: #006dcc;
	background-image: -moz-linear-gradient(to bottom, #08c, #04c);
	background-image: -ms-linear-gradient(to bottom, #08c, #04c);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#04c));
	background-image: -webkit-linear-gradient(to bottom, #08c, #04c);
	background-image: -o-linear-gradient(to bottom, #08c, #04c);
	background-image: linear-gradient(to bottom, #08c, #04c);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08c', endColorstr='#0044cc', GradientType=0);
	border-color: #04c #04c #002a80;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	color: #fff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td.active.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active.disabled:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active[disabled] {
	background-color: #04c;
}
.datepicker table tr td.active.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active:hover:active {
	background-color: #039;
}
.datepicker table tr td span {
	display: block;
	width: 23%;
	height: 54px;
	line-height: 54px;
	float: left;
	margin: 1%;
	cursor: pointer;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}
.datepicker table tr td span.focused,
.datepicker table tr td span:hover {
	background: $sg-500;
	color: invert($color: $white);
}
.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
	background: 0 0;
	color: $sg-100;
	cursor: default;
}
.datepicker table tr td span.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active:hover {
	background-color: #006dcc;
	background-image: -moz-linear-gradient(to bottom, #08c, #04c);
	background-image: -ms-linear-gradient(to bottom, #08c, #04c);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#04c));
	background-image: -webkit-linear-gradient(to bottom, #08c, #04c);
	background-image: -o-linear-gradient(to bottom, #08c, #04c);
	background-image: linear-gradient(to bottom, #08c, #04c);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08c', endColorstr='#0044cc', GradientType=0);
	border-color: #04c #04c #002a80;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	color: #fff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td span.active.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active.disabled:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active[disabled] {
	background-color: #04c;
}
.datepicker table tr td span.active.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active:hover:active {
	background-color: #039;
}
// .datepicker table tr td span.new,
// .datepicker table tr td span.old {
//   color: #999;
// }
.datepicker .datepicker-switch {
	width: 145px;
}
.datepicker .datepicker-switch,
.datepicker .next,
.datepicker .prev,
.datepicker tfoot tr th {
	cursor: pointer;
}
.datepicker .datepicker-switch:hover,
.datepicker .next:hover,
.datepicker .prev:hover,
.datepicker tfoot tr th:hover {
	background: #eee;
}
.datepicker .next.disabled,
.datepicker .prev.disabled {
	visibility: hidden;
}
.datepicker .cw {
	font-size: 10px;
	width: 12px;
	padding: 0 2px 0 5px;
	vertical-align: middle;
}
.input-append.date .add-on,
.input-prepend.date .add-on {
	cursor: pointer;
}
.input-append.date .add-on i,
.input-prepend.date .add-on i {
	margin-top: 3px;
}
.input-daterange input {
	text-align: center;
}
.input-daterange input:first-child {
	-webkit-border-radius: 3px 0 0 3px;
	-moz-border-radius: 3px 0 0 3px;
	border-radius: 3px 0 0 3px;
}
.input-daterange input:last-child {
	-webkit-border-radius: 0 3px 3px 0;
	-moz-border-radius: 0 3px 3px 0;
	border-radius: 0 3px 3px 0;
}
.input-daterange .add-on {
	display: inline-block;
	width: auto;
	min-width: 16px;
	height: 18px;
	padding: 4px 5px;
	font-weight: 400;
	line-height: 18px;
	text-align: center;
	text-shadow: 0 1px 0 #fff;
	vertical-align: middle;
	background-color: #eee;
	border: 1px solid #ccc;
	margin-left: -5px;
	margin-right: -5px;
}

/*!
 * Timepicker Component for Twitter Bootstrap
 *
 * Copyright 2013 Joris de Wit
 *
 * Contributors https://github.com/jdewit/bootstrap-timepicker/graphs/contributors
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
.bootstrap-timepicker {
	position: relative;
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu {
	left: auto;
	right: 0;
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:before {
	left: auto;
	right: 12px;
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:after {
	left: auto;
	right: 13px;
}
.bootstrap-timepicker .input-group-addon {
	cursor: pointer;
}
.bootstrap-timepicker .input-group-addon i {
	display: inline-block;
	width: 16px;
	height: 16px;
}
.bootstrap-timepicker-widget.dropdown-menu {
	padding: 4px;
}
.bootstrap-timepicker-widget.dropdown-menu.open {
	display: inline-block;
}
.bootstrap-timepicker-widget.dropdown-menu:before {
	border-bottom: 7px solid rgba(0, 0, 0, 0.2);
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	content: "";
	display: inline-block;
	position: absolute;
}
.bootstrap-timepicker-widget.dropdown-menu:after {
	border-bottom: 6px solid #fff;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	content: "";
	display: inline-block;
	position: absolute;
}
.bootstrap-timepicker-widget.timepicker-orient-left:before {
	left: 6px;
}
.bootstrap-timepicker-widget.timepicker-orient-left:after {
	left: 7px;
}
.bootstrap-timepicker-widget.timepicker-orient-right:before {
	right: 6px;
}
.bootstrap-timepicker-widget.timepicker-orient-right:after {
	right: 7px;
}
.bootstrap-timepicker-widget.timepicker-orient-top:before {
	top: -7px;
}
.bootstrap-timepicker-widget.timepicker-orient-top:after {
	top: -6px;
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
	bottom: -7px;
	border-bottom: 0;
	border-top: 7px solid #999;
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
	bottom: -6px;
	border-bottom: 0;
	border-top: 6px solid #fff;
}
.bootstrap-timepicker-widget a.btn,
.bootstrap-timepicker-widget input {
	border-radius: 4px;
}
.bootstrap-timepicker-widget table {
	width: 100%;
	margin: 0;
}
.bootstrap-timepicker-widget table td {
	text-align: center;
	height: 30px;
	margin: 0;
	padding: 2px;
}
.bootstrap-timepicker-widget table td:not(.separator) {
	min-width: 30px;
}
.bootstrap-timepicker-widget table td span {
	width: 100%;
}
.bootstrap-timepicker-widget table td a {
	border: 1px transparent solid;
	width: 100%;
	display: inline-block;
	margin: 0;
	padding: 8px 0;
	outline: 0;
	color: #333;
}
.bootstrap-timepicker-widget table td a:hover {
	text-decoration: none;
	background-color: #eee;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	border-color: #ddd;
}
.bootstrap-timepicker-widget table td a i {
	margin-top: 2px;
	font-size: 18px;
}
.bootstrap-timepicker-widget table td input {
	width: 25px;
	margin: 0;
	text-align: center;
}
.bootstrap-timepicker-widget .modal-content {
	padding: 4px;
}
@media (min-width: 767px) {
	.bootstrap-timepicker-widget.modal {
		width: 200px;
		margin-left: -100px;
	}
}
@media (max-width: 767px) {
	.bootstrap-timepicker {
		width: 100%;
	}
	.bootstrap-timepicker .dropdown-menu {
		width: 100%;
	}
}

/*!
 * Timepicker Component for Twitter Bootstrap
 *
 * Copyright 2013 Joris de Wit
 *
 * Contributors https://github.com/jdewit/bootstrap-timepicker/graphs/contributors
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
.bootstrap-timepicker {
	position: relative;
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu {
	left: auto;
	right: 0;
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:before {
	left: auto;
	right: 12px;
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:after {
	left: auto;
	right: 13px;
}
.bootstrap-timepicker .input-group-addon {
	cursor: pointer;
}
.bootstrap-timepicker .input-group-addon i {
	display: inline-block;
	width: 16px;
	height: 16px;
}
.bootstrap-timepicker-widget.dropdown-menu {
	padding: 4px;
}
.bootstrap-timepicker-widget.dropdown-menu.open {
	display: inline-block;
}
.bootstrap-timepicker-widget.dropdown-menu:before {
	border-bottom: 7px solid rgba(0, 0, 0, 0.2);
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	content: "";
	display: inline-block;
	position: absolute;
}
.bootstrap-timepicker-widget.dropdown-menu:after {
	border-bottom: 6px solid #fff;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	content: "";
	display: inline-block;
	position: absolute;
}
.bootstrap-timepicker-widget.timepicker-orient-left:before {
	left: 6px;
}
.bootstrap-timepicker-widget.timepicker-orient-left:after {
	left: 7px;
}
.bootstrap-timepicker-widget.timepicker-orient-right:before {
	right: 6px;
}
.bootstrap-timepicker-widget.timepicker-orient-right:after {
	right: 7px;
}
.bootstrap-timepicker-widget.timepicker-orient-top:before {
	top: -7px;
}
.bootstrap-timepicker-widget.timepicker-orient-top:after {
	top: -6px;
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
	bottom: -7px;
	border-bottom: 0;
	border-top: 7px solid #999;
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
	bottom: -6px;
	border-bottom: 0;
	border-top: 6px solid #fff;
}
.bootstrap-timepicker-widget a.btn,
.bootstrap-timepicker-widget input {
	border-radius: 4px;
}
.bootstrap-timepicker-widget table {
	width: 100%;
	margin: 0;
}
.bootstrap-timepicker-widget table td {
	text-align: center;
	height: 30px;
	margin: 0;
	padding: 2px;
}
.bootstrap-timepicker-widget table td:not(.separator) {
	min-width: 30px;
}
.bootstrap-timepicker-widget table td span {
	width: 100%;
}
.bootstrap-timepicker-widget table td a {
	border: 1px transparent solid;
	width: 100%;
	display: inline-block;
	margin: 0;
	padding: 8px 0;
	outline: 0;
	color: #333;
}
.bootstrap-timepicker-widget table td a:hover {
	text-decoration: none;
	background-color: #eee;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	border-color: #ddd;
}
.bootstrap-timepicker-widget table td a i {
	margin-top: 2px;
	font-size: 18px;
}
.bootstrap-timepicker-widget table td input {
	width: 25px;
	margin: 0;
	text-align: center;
}
.bootstrap-timepicker-widget .modal-content {
	padding: 4px;
}
@media (min-width: 767px) {
	.bootstrap-timepicker-widget.modal {
		width: 200px;
		margin-left: -100px;
	}
}
@media (max-width: 767px) {
	.bootstrap-timepicker {
		width: 100%;
	}
	.bootstrap-timepicker .dropdown-menu {
		width: 100%;
	}
}

// //
// // dropzone.scss
// //

// .dropzone {
//   border: 2px dashed $input-border-color;
//   background: $input-bg;
//   border-radius: 6px;
//   cursor: pointer;
//   min-height: 150px;
//   padding: 20px;

//   .dz-message {
//       text-align: center;
//       margin: 2rem 0;
//   }
//   &.dz-started {
//       .dz-message {
//           display: none;
//       }
//   }
// };

// .dropzone-previews{
// height: auto;
// }
