//
// flickity.scss
// Plugin overrides
//

// Controls
.flickity-button {
  &.flickity-prev-next-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $input-height;
    height: $input-height;
    background-color: $secondary;
    box-shadow: $box-shadow-inset;
    color: $white;

    &::before,
    &::after {
      font-family: "Material Design Icons";
    }

    &:hover,
    &:focus {
      background-color: $light;
      color: $black;
      box-shadow: $box-shadow;
    }
  }
}

.flickity-prev-next-button.previous {
  left: 0;
  transform: translate(calc(-50% + #{$grid-gutter-width * 0.25}), -50%);

  @include media-breakpoint-up(lg) {
    transform: translate(-50%, -50%);
  }

  &::before {
    content: "\F0141";
    font-size: 24px;
  }
}

.flickity-prev-next-button.next {
  right: 0;
  transform: translate(calc(50% - #{$grid-gutter-width * 0.25}), -50%);

  @include media-breakpoint-up(lg) {
    transform: translate(50%, -50%);
  }

  &::before {
    content: "\F0142";
    font-size: 24px;
  }
}

.flickity-button-icon {
  display: none;
}

// Button white

.flickity-button-white .flickity-prev-next-button {
  background-color: $light;
  color: $primary;
}

// Button bottom

.flickity-button-bottom .flickity-prev-next-button {
  top: auto;
  bottom: 1.5rem;
  transform: none;
}

.flickity-button-bottom .flickity-prev-next-button.previous {
  left: auto;
  right: 6.375rem;
}

.flickity-button-bottom .flickity-prev-next-button.next {
  left: auto;
  right: 2.5rem;
}

// Button inset

.flickity-button-inset .flickity-prev-next-button {
  transform: translateY(-50%);
  &.next {
    // right: 1.5rem;
    @include media-breakpoint-down("sm") {
      right: calc(50% - 180px - 0.1rem);
    }
    @include media-breakpoint-up("md") {
      right: calc(50% - 180px - 0.75rem);
    }
  }

  &.previous {
    @include media-breakpoint-down("sm") {
      left: calc(50% - 180px - 0.1rem);
    }
    @include media-breakpoint-up("md") {
      left: calc(50% - 180px - 0.75rem);
    }
  }
}

// Viewport

.flickity-viewport-visible .flickity-viewport {
  overflow: visible;
}

// Adaptive height

[data-flickity*='"adaptiveHeight": true'] .flickity-viewport {
  transition: all 0.5s ease; // Make the transition match the rough duration of flicking between items
}

// Fixing .col gutters because Bootstrap sets it to 0 if they are not direct children of a .row

.flickity-slider > [class^="col"] {
  padding-left: $grid-gutter-width * 0.5;
  padding-right: $grid-gutter-width * 0.5;
}
.flickity-button-inset {
  ol.flickity-page-dots {
    li.dot {
      background: $light;

      &li.is-selected {
        background: $primary;
      }
    }
  }
}
